import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  Account,
  areSubscriptionFeaturesActive,
  ChecklistValues,
  getDaysSinceShopCreation,
  Shop,
} from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { listAllTransactionsAction } from '../../store/reducers/transactions';
import queryString from 'query-string';
import './App.scss';
import Layout from '../Layout/index';
import * as Sentry from '@sentry/react';

// Routes
import AuthLayout from '../Layout/AuthLayout';
import Login from '../Auth/Login/index';
import ForgotPassword from '../Auth/ForgotPassword';
import Dashboard from '../Dashboard';
import Products from '../Products/Products';
import EditProduct from '../Products/EditProduct';
import Presales from '../Presales';
import OrganizeProducts from '../Products/OrganizeProducts';
import Orders from '../Orders';
import Marketing from '../Messaging';
import EditUpdateEmail from '../Messaging/EditUpdateEmail';
import Customers from '../Customers/Customers';
import Analytics from '../Analytics';
import CustomerView from '../Customers/CustomerView';
import AddCustomers from '../Customers/AddCustomers';
import Payments from '../Store/Payments';
import SettingsDashboard from '../Store/SettingsDashboard';
import Appearance from '../Store/Website/Forms/Appearance';
import BusinessDetails from '../Store/Website/Forms/BusinessDetails';
import Gallery from '../Store/Pages/Gallery';
import Resources from '../Resources/Resources';
import Coupons from '../Coupons';
import EditCoupon from '../Coupons/EditCoupon';
import SubscriptionPlans from '../Store/SubscriptionPlans';
import DomainsLandingPage from '../Store/Domains';
// ------------------------------------------------------
import { getMyselfAction, setJustLoggedIn, signOutAction } from '../../store/reducers/users';
import RootModal from '../RootModal/RootModal';
import { FirebaseContext, useConfig } from '@castiron/castiron-firebase';
import AccountSettings from '../AccountSettings';
import ContactUs from '../ContactUs';
import OrderView from '../Orders/OrderView';
import {
  convertToDefaultTierAction,
  getShopAction,
  setAccount,
  setDiscountAction,
  setRedirectUrl,
  setShop,
  setStripeRefresh,
} from '../../store/reducers/shops';
import Spinner from '../Spinner';
import EditNewSubscriberEmail from '../Messaging/EditNewSubscriberEmail';
import EditWelcomeSeries from '../Messaging/EditWelcomeSeries';
import OnboardingSignup from '../Onboarding';
import Onboarding from '../Onboarding/Onboarding';
import SegmentPageTracking from '../SegmentPageTracking';
import { UserflowTracking, UserflowOnboardingTracking } from '../UserflowTracking';
import ThanksNotUS from '../ThanksNotUS';
import { Flibble, useFeatures } from '@castiron/components';
import { User } from '../../models/User';
import RequireValidEmail from '../Auth/RequireValidEmail';
import ExistingAccount from '../Auth/ExistingAccount';
import QuotesView from '../Quotes/QuotesView';
import EditQuote from '../Quotes/EditQuote';
import CustomersRedirect from '../CustomersRedirect';
import Calendar from '../Calendar';
import Fulfillment from '../Store/Fulfillment';
import EditFulfillment from '../Store/Fulfillment/Edit';
import SearchTest from '../SearchTest';
import CalendarPreview from '../Calendar/Preview';
import GatedRoute from '../GatedRoute';
import Unpaid from '../Unpaid';
import { setDebugParam } from '../../store/reducers/debug';
import SeoPage from '../Store/Seo';
import ShopPages from '../Store/Pages';
import AboutPage from '../Store/Pages/AboutPage';
import AvailabilityPage from '../Store/Pages/AvailabilityPage';
import ContactPage from '../Store/Pages/ContactPage';
import CustomPage from '../Store/Pages/CustomPage';
import EventPage from '../Store/Pages/EventPage';
import FaqPage from '../Store/Pages/FaqPage';
import ShopHomePage from '../Store/Pages/HomePage';
import QuotesPage from '../Store/Pages/QuotesPage';
import ShopPage from '../Store/Pages/ShopPage';
import { openModal } from '../../store/reducers/modalConductor';
import moment from 'moment';
import DiscountLink from '../DiscountLink';
import GenerateMenuTest from '../GenerateMenuTest';
import Notifications from '../Store/Notifications';
import MobileNumberVerification from '../MobileNumberVerification';
import ThemeSelect from '../Store/Website/ThemeSelect';
import OrderForms from '../Products/OrderForms';
import EditPresale from '../Presales/EditPresale';
import PresalesPage from '../Store/Pages/PresalesPage';
import { getService } from '../../firebase';
import { tierRepository } from '../../domain';
import SingleSendEmail from '../Messaging/Emails/SingleSendEmail';
import InvoiceCheckout from '../Test/InvoiceCheckout';
import SingleSendSms from '../Messaging/Sms/SingleSendSms';
import GenerateProduct from '../Test/GenerateProduct';
import TotalsApi from '../Test/TotalsApi';
import EditNewsletterSeries from '../Messaging/Automations/EditNewsletterSeries';
import Events from '../Products/Events';
import TicketedEventsPage from '../Store/Pages/TicketedEventsPage';

const updatePayoutSettingsService = getService('stripe', 'updatepayoutsettings');
const DEFAULT_TIER_ID = 'jPAnKCplJ58JtJMLScQ5'; // Unpaid
const castironTiers = ['jW1TZgc3sn9iKMcWlghl', 'R3p07UAtSVjoskTkMUjo'];

const requireValidEmail = (user: User, shop: Shop): boolean => false; //Disabling for now until we can take on the full validation flow from Google
//shop?.tags?.includes('MadLibsOnboarding') && !user?.isEmailValid;

declare global {
  interface Window {
    CelloAttribution(action: 'getReferral' | 'attachAll'): string;
  }
}

const { REACT_APP_SHOP_URL } = process.env;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const FirebaseApp = useContext(FirebaseContext);
  const { trackUser, trackEvent } = useTracking();
  const features = useFeatures();
  const {
    me,
    fromOnboarding,
    hasLoaded,
    modalProps,
    modalType,
    shop,
    account,
    isOnboarding,
    justLoggedIn,
    tier,
    subscription,
    transactions,
    isReady,
    stripeLoginLink,
  } = useAppSelector(state => ({
    hasLoaded: state.shops.hasLoaded,
    me: state.users.me,
    fromOnboarding: state.shops.fromOnboarding,
    modalProps: state.modal.modalProps,
    modalType: state.modal.modalType,
    shop: state.shops.shop,
    account: state.shops.account,
    isOnboarding: state.shops.isOnboarding,
    justLoggedIn: state.users.justLoggedIn,
    transactions: state.transactions.transactions,
    tier: state.shops.account?.tier,
    subscription: state.shops.account?.subscription,
    isReady: state.shops.account?.isReady,
    stripeLoginLink: state.shops.stripe?.stripeLink,
  }));

  const ffconfig = useConfig();
  const earlyAdopterDiscountEnd = ffconfig && ffconfig.config().getNumber('feature_castiron_early_adopter_end_date');
  const todayUnix = moment().unix();
  const isEarlyAdopter = account?.isEarlyAdopter() && todayUnix < earlyAdopterDiscountEnd;

  const [pageTitle, setPageTitle] = useState('');
  const [pageIsProFeature, setPageIsProFeature] = useState(false);
  const [headerCTAs, setHeaderCTAs] = useState<ReactNode[]>([]);
  const [footerCTAs, setFooterCTAs] = useState<ReactNode[]>([]);
  const [backLocation, setBackLocation] = useState<boolean | string>(false);
  const [optFooterFormat, setOptFooterFormat] = useState('');
  const [longTitle, setLongTitle] = useState(false);
  const [displayNav, setDisplayNav] = useState(true);
  const [transaction, setHeaderTransaction] = useState(null);
  const [transactionContext, setHeaderTransactionContext] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [triggeredStripeConnect, setTriggeredStripeConnect] = useState(false);

  const baseLayoutPageProps = {
    setPageTitle,
    setPageIsProFeature,
    setBackLocation,
    setLongTitle,
    setOptFooterFormat,
    setHeaderCTAs,
    setFooterCTAs,
    setDisplayNav,
    setHeaderTransaction,
    setHeaderTransactionContext,
  };

  const [setupChecklistMode, setSetupChecklistMode] = useState<'prelaunch' | 'postlaunch' | 'selling'>(null);
  const [isShopListenerStarted, setIsShopListenerStarted] = useState<boolean>(false);
  const [isAccountListenerStarted, setIsAccountListenerStarted] = useState<boolean>(false);
  const [isUnpaid, setIsUnpaid] = useState<boolean>(false);
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const lastStepComplete =
    account?.subscription?.status === 'pending' ||
    account?.subscription?.status === 'active' ||
    account?.subscription?.status === 'pending-canceled' ||
    account?.subscription?.status === 'trial';
  const stripeCreateAccountService = getService('stripe', 'stripecreateaccountv2', { version: 2 });

  const updatePayoutSettings = async () => {
    // Update payout from weekly to daily after two weeks
    const shopCreatedAfterTwoWeeks = getDaysSinceShopCreation(account?.createdAt) >= 14;
    const accountIsCastironTier = castironTiers.includes(account?.tier?.id);
    if (shopCreatedAfterTwoWeeks && accountIsCastironTier) {
      const tier = await tierRepository.get(account?.tier?.id);
      updatePayoutSettingsService({
        settings: {
          payouts: {
            schedule: {
              interval: tier.payoutFrequency,
              weekly_anchor: tier.payoutFrequency === 'weekly' ? 'monday' : undefined,
              delay_days: 'minimum',
            },
          },
        },
      });
    }
  };

  const handleStripeConnect = async () => {
    setTriggeredStripeConnect(true);
    console.debug('start stripe connect');
    const redirectUrl = `${process.env.REACT_APP_ADMIN_URL}new?fromStripe=true&dest=ttpapp`;
    try {
      const body = {
        redirectUrl,
      };
      if (!account.stripeAccountId) {
        stripeCreateAccountService(body).then(r => {
          console.debug('stripe create account response', r);
          trackEvent('Stripe Setup Started');
          window.location.href = r.url;
        });
      } else {
        window.location.href = stripeLoginLink;
      }
    } catch (err) {
      setShowSpinner(false);
      console.error('Error in handleStripeConnect: ', err);
    }
  };

  useEffect(() => {
    if (account && params.ttpOnboarding && !triggeredStripeConnect) {
      handleStripeConnect();
    }
  }, [account]);

  useEffect(() => {
    if (account && castironTiers.includes(account?.tier?.id)) {
      updatePayoutSettings();
    }
  }, [account]);

  useEffect(() => {
    if (params.logout === 'true') {
      dispatch(signOutAction('/'));
    }

    if (params.referralCode) {
      sessionStorage.setItem('friendbuy-referralCode', params.referralCode);
    }

    const debugParams = Object.keys(params).filter(p => p.startsWith('debug.'));

    debugParams.forEach(p => {
      const path = p.substring(6);
      const value = params[p];
      dispatch(
        setDebugParam({
          path,
          value,
        }),
      );
    });

    if (window.CelloAttribution) {
      window.CelloAttribution('attachAll');
    }
  }, []);

  useEffect(() => {
    setIsUnpaid(
      tier?.name === 'Unpaid' ||
        !areSubscriptionFeaturesActive(subscription) ||
        (account?.status === 'onboarding' && !lastStepComplete),
    );
  }, [tier, subscription, account]);

  // Might be able to use this to lock out accounts (i.e. move to unpaid) after Jan 15. But not sure how to handle sub canceled, etc
  // useEffect(() => {
  //   if (isUnpaid && tier?.name === 'Unpaid') {
  //     dispatch(convertToDefaultTierAction(shop.id));
  //   }
  // }, [isUnpaid, tier]);

  useEffect(() => {
    if (shop?.id) {
      Sentry?.setContext('shop', {
        id: shop.id,
        name: shop.businessName,
        websiteUrl: shop.websiteUrl,
      });
      Sentry?.withScope(scope => {
        scope.setTag('shopId', shop.id);
      });
      const isActive = shop?.status === 'active';
      const hasSold = transactions?.some(tx => tx.transactionStatus === 'succeeded');
      if (isActive && hasSold) {
        setSetupChecklistMode('selling');
      } else if (isActive) {
        setSetupChecklistMode('postlaunch');
      } else {
        setSetupChecklistMode('prelaunch');
      }

      if (!isShopListenerStarted && shop.listen) {
        console.debug('Starting Shop Listener!');
        setIsShopListenerStarted(true);
        let shopLaunched = shop.hasEverLaunched;
        shop.listen((s: Shop, source) => {
          if (source === 'remote') {
            dispatch(setShop(s));

            if (!shopLaunched && s.hasEverLaunched) {
              shopLaunched = true;
              trackEvent('Owner Launched Shop');
            }
          }
        });
      }
    }
  }, [shop, transactions]);

  useEffect(() => {
    if (account && !isAccountListenerStarted && account.listen) {
      console.debug('Starting Account Listener!');
      setIsAccountListenerStarted(true);
      account.listen((a: Account) => {
        dispatch(setAccount(a));
      });
    }
    if (account?.status === 'onboarding' && !lastStepComplete) {
      history.push(`/signup/info/${account?.onboardingQuestions?.onboardingStep?.step || 1}`);
    }

    if (account) {
      const discountCode = params.code || sessionStorage.getItem('discountCode');
      const code = account?.subscription?.discount?.code;
      if (discountCode || code) {
        dispatch(setDiscountAction(discountCode || code));
      }

      if (params.fromStripe && account.integrations.stripe.accountId) {
        trackEvent('Owner Completed Stripe');
      }
    }
  }, [account]);

  const getToFromQueryString = () => {
    const qs = queryString.parse(location.search);
    console.log('qs: ', qs);
    if (qs.to) {
      return qs.to;
    }

    return '';
  };

  const getStripeRefreshFromQueryString = () => {
    const qs = queryString.parse(location.search);
    console.log('qs: ', qs);
    if (qs.refresh) {
      return qs.refresh;
    }

    return '';
  };

  useEffect(() => {
    const redirectUrl = getToFromQueryString().toString();
    const isStripeRefresh = getStripeRefreshFromQueryString();
    dispatch(setRedirectUrl(redirectUrl));
    dispatch(setStripeRefresh(isStripeRefresh));

    FirebaseApp.auth().onAuthStateChanged(user => {
      console.debug('Auth Change', { user });
      if (user) {
        if (user.metadata.creationTime)
          dispatch(
            getMyselfAction({
              email: user.email,
              uid: user.uid,
              displayName: user.displayName,
              isEmailValid: user.emailVerified,
              createdAt: Date.parse(user.metadata.creationTime),
            }),
          );
      } else {
        dispatch(getMyselfAction(undefined));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (me) {
      if (!shop && !isOnboarding) dispatch(getShopAction(me.uid));
      trackUser();
      Sentry?.setUser({
        id: me.uid,
        email: me.email,
      });
    }
    if (shop) {
      if (!(pathname === '/orders' || pathname === '/quotes')) {
        dispatch(listAllTransactionsAction(shop.id));
      }
    }
  }, [me, shop]);

  useEffect(() => {
    if (shop) {
      window.Beacon('identify', {
        name: shop.owner?.firstName ? `${shop.owner.firstName} ${shop.owner.lastName}` : shop.businessName,
        email: me.email,
        company: shop.businessName,
        'shop-id': shop.id,
        'shop-url': REACT_APP_SHOP_URL + shop.websiteUrl,
        'hubspot-link': `https://app.hubspot.com/contacts/8719425/record/0-1/${account.integrations?.hubspot?.id}`,
      });
    }
  }, [shop, subscription, account]);

  const isAuthed = useCallback(() => {
    return isOnboarding ? false : !!me;
  }, [me, isOnboarding]);

  useEffect(() => {
    if (me && shop && justLoggedIn && setupChecklistMode != null) {
      dispatch(setJustLoggedIn(false));

      const showEarlyAdopterPricingPage = !localStorage.getItem('seenEarlyAdopterPage');
      if (isEarlyAdopter && showEarlyAdopterPricingPage) {
        history.push(`/store/plans/select?pathname=${location.pathname}`);
      }

      const showDomainError =
        features?.includes('shop.customDomain') &&
        moment().diff(moment.unix(shop?.config?.domainRequestedAt), 'hours') > 48 &&
        shop?.config?.domainStatus === 'pending';
      const seenDomainManagementErrorModal = localStorage.getItem('seenDomainManagementErrorModal');
      if (showDomainError && !seenDomainManagementErrorModal) {
        const domain = shop?.config?.domains?.find(domain => domain.startsWith('www'));
        if (domain) {
          dispatch(
            openModal({
              modalType: 'DOMAIN_MANAGEMENT_MODAL',
              modalProps: {
                show: true,
                domain: domain || '',
                type: 'error',
              },
            }),
          );
        }
      }
    }
  }, [me, shop, account, setupChecklistMode]);

  useEffect(() => {
    /* Open Publish Shop Modal if all steps are completed and we're just waiting on Stripe Connection */
    const seenPrelaunchModal = localStorage.getItem('seenPrelaunchModal');
    if (shop?.status === 'prelaunch' && isReady && !seenPrelaunchModal && !params.fromStripe) {
      dispatch(
        openModal({
          modalType: 'PRELAUNCH_MODALS',
          modalProps: {
            show: true,
            stepCompleted: ChecklistValues.GoLive,
          },
        }),
      );
    }
  }, [isReady]);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const authenticatedRoutes = (
    <SegmentPageTracking isUnauthed={false}>
      <UserflowTracking />
      <Layout
        pageTitle={pageTitle}
        pageIsProFeature={pageIsProFeature}
        backLocation={backLocation}
        longTitle={longTitle}
        optFooterFormat={optFooterFormat}
        headerCTAs={headerCTAs}
        footerCTAs={footerCTAs}
        displayNav={displayNav}
        transaction={transaction}
        transactionContext={transactionContext}
      >
        {/* <Route path="/discount/:code">
          <DiscountLink isAuth={true} />
        </Route>
        <Route path="/discount/:frequency/:code">
          <DiscountLink isAuth={true} />
        </Route> */}
        <Route path="/" exact>
          <Dashboard {...baseLayoutPageProps} />
        </Route>
        <Route path="/genmenu">
          <GenerateMenuTest />
        </Route>
        <Route path="/new" exact>
          <UserflowOnboardingTracking />
          <Dashboard {...baseLayoutPageProps} popChat={false} />
        </Route>
        <Route path="/search" exact>
          <SearchTest {...baseLayoutPageProps} />
        </Route>
        <GatedRoute feature="admin.products" path="/products" exact>
          <Products {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/add">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/custom/:productTemplateId">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/edit/:id?">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/organize/:categoryName?">
          <OrganizeProducts {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/order-forms" exact>
          <OrderForms {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/order-forms/add">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/order-forms/custom/:productTemplateId">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/order-forms/edit/:id?">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/order-forms/organize/:categoryName?">
          <OrganizeProducts {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/events" exact>
          <Events {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/events/add">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/events/custom/:productTemplateId">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/events/edit/:id?">
          <EditProduct {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/events/organize/:categoryName?">
          <OrganizeProducts {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.presales" path="/presales" exact>
          <Presales {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.presales" path="/presales/add">
          <EditPresale {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.presales" path="/presales/edit/:id?">
          <EditPresale {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.orders" path="/orders" exact>
          <Orders {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.orders" path="/orders/add" exact>
          <OrderView {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.orders" path="/orders/edit/:id?" exact>
          <OrderView {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute
          feature="admin.quotes"
          path="/orders/customorderedit/:id?"
          exact
          render={({ match }) => <Redirect to={`/quotes/edit/${match.params.id}`} />}
        />
        <GatedRoute feature="admin.quotes" path="/quotes" exact>
          <QuotesView {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.quotes" path="/quotes/edit/:id?" exact>
          <EditQuote {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts" exact>
          <Redirect path="/contacts" to="/contacts/overview" />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts/overview" exact>
          <Customers {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts/details/:id?" exact>
          <CustomerView {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts/add" exact>
          <AddCustomers {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/customers">
          <CustomersRedirect />
        </GatedRoute>

        {/* marketing redirects */}
        <GatedRoute feature="admin.marketing" path="/marketing/overview">
          <Redirect path="/marketing/overview" to="/marketing/email" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing?tab=SMS">
          <Redirect exact path="/marketing?tab=SMS" to="/marketing/sms" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing?tab=Emails">
          <Redirect exact path="/marketing?tab=Emails" to="/marketing/email" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing?tab=Automations">
          <Redirect exact path="/marketing?tab=Automations" to="/marketing/automations" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing?tab=Popups">
          <Redirect exact path="/marketing?tab=Popups" to="/marketing/popups" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing?tab=Social">
          <Redirect exact path="/marketing?tab=Social" to="/marketing/social" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing" exact strict>
          <Redirect path="/marketing" to="/marketing/email" exact strict />
        </GatedRoute>
        {/* marketing tabs */}
        <GatedRoute feature="admin.marketing" path="/marketing/sms" exact>
          <Marketing {...baseLayoutPageProps} subpage="SMS" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/email" exact>
          <Marketing {...baseLayoutPageProps} subpage="Email" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/automations" exact>
          <Marketing {...baseLayoutPageProps} subpage="Automations" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/popups" exact>
          <Marketing {...baseLayoutPageProps} subpage="Popups" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/social" exact>
          <Marketing {...baseLayoutPageProps} subpage="Social" />
        </GatedRoute>
        {/* <GatedRoute feature="admin.marketing" path="/marketing/campaigns" exact>
          <Marketing {...baseLayoutPageProps} initialTab="Campaigns" />
        </GatedRoute> */}
        {/* marketing pages */}
        <GatedRoute feature="admin.marketing" path="/marketing/edit-update-email">
          <EditUpdateEmail {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/edit-new-subscriber-email">
          <EditNewSubscriberEmail {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/edit-welcome-series-emails">
          <EditWelcomeSeries {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/edit-newsletter-series">
          <EditNewsletterSeries {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/new-product-email/:id?" exact>
          <Redirect to="/marketing/single-send-email?type=new-product" />
        </GatedRoute>
        <GatedRoute feature="admin.presales" path="/marketing/new-presale-email/:id?" exact>
          <Redirect to="/marketing/single-send-email?type=new-presale" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/new-custom-availability" exact>
          <Redirect to="/marketing/single-send-email?type=custom-order-available" />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/single-send-email" exact>
          <SingleSendEmail {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/single-send-email/scheduled/:id?" exact>
          <SingleSendEmail {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/single-send-sms" exact>
          <SingleSendSms {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/single-send-sms/scheduled/:id?" exact>
          <SingleSendSms {...baseLayoutPageProps} />
        </GatedRoute>

        <GatedRoute feature="admin.analytics" path="/analytics" exact>
          <Analytics {...baseLayoutPageProps} />
        </GatedRoute>
        <Route exact path="/store">
          <Redirect path="/store" to="/store/dashboard" />
        </Route>
        <Route path="/store/settings" exact>
          <Redirect to="/store/dashboard" />
        </Route>
        <Route path="/store/dashboard">
          <SettingsDashboard {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/appearance">
          <Appearance {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/theme">
          <ThemeSelect {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/gallery">
          <Gallery {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/business-details">
          <BusinessDetails {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/fulfillment" exact>
          <Fulfillment {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/fulfillment/create/:type?" exact>
          <EditFulfillment {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/fulfillment/edit/:id" exact>
          <EditFulfillment {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/payments" exact>
          <Payments {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/plans" exact>
          <SubscriptionPlans {...baseLayoutPageProps} />
        </Route>
        <GatedRoute feature="admin.subscriptions" path="/store/plans/payment" exact>
          <SubscriptionPlans {...baseLayoutPageProps} openPaymentModal={true} />
        </GatedRoute>
        <Route path="/store/plans/select" exact>
          <Unpaid {...baseLayoutPageProps} />
        </Route>
        <Route path="/store/notifications" exact>
          <Notifications {...baseLayoutPageProps} />
        </Route>
        <Route path="/resources" exact>
          <Resources {...baseLayoutPageProps} />
        </Route>
        <Route path="/account-settings" exact>
          <AccountSettings {...baseLayoutPageProps} />
        </Route>
        <Route path="/contact-us" exact>
          <ContactUs {...baseLayoutPageProps} />
        </Route>
        <GatedRoute feature="admin.coupons" path="/store/coupons" exact>
          <Coupons {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.customDomain" path="/store/domains" exact>
          <DomainsLandingPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.coupons" path="/coupons/new" exact>
          <EditCoupon {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.coupons" path="/coupons/edit/:id" exact>
          <EditCoupon editing {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.calendar" path="/calendar" exact>
          <Calendar {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.seo" path="/store/seo" exact>
          <SeoPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/pages" exact>
          <ShopPages {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/about" exact>
          <AboutPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/availability" exact>
          <AvailabilityPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/contact" exact>
          <ContactPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/custom" exact>
          <CustomPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/event/:name" exact>
          <EventPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/faq" exact>
          <FaqPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/home" exact>
          <ShopHomePage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/order-forms" exact>
          <QuotesPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/events" exact>
          <TicketedEventsPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="shop.subpages" path="/store/shop" exact>
          <ShopPage {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.presales" path="/store/presales" exact>
          <PresalesPage {...baseLayoutPageProps} />
        </GatedRoute>
        <Route path="/test/invoice/checkout">
          <InvoiceCheckout />
        </Route>
        <Route path="/test/product/generate">
          <GenerateProduct />
        </Route>
        <Route path="/test/order/quote">
          <TotalsApi />
        </Route>
        <Route path="/phone/verify/:code" exact>
          <MobileNumberVerification />
        </Route>
      </Layout>
    </SegmentPageTracking>
  );

  const unauthenticatedRoutes = (
    <SegmentPageTracking isUnauthed={true}>
      <Switch>
        {/* <Route path="/discount/:code">
          <DiscountLink isAuth={false} />
        </Route>
        <Route path="/discount/:frequency/:code">
          <DiscountLink isAuth={false} />
        </Route> */}
        <Route path="/signup" exact>
          <OnboardingSignup {...baseLayoutPageProps} />
        </Route>
        <Route path="/signup/:step" exact>
          <OnboardingSignup {...baseLayoutPageProps} />
        </Route>
        <Route path="/signup/international" exact>
          <Onboarding {...baseLayoutPageProps} />
        </Route>
        <Route path="/signup/account/existing" exact>
          <ExistingAccount />
        </Route>
        <Route path="/thanks-not-us" exact>
          <ThanksNotUS />
        </Route>
        <Route path="/forgot-password" exact>
          <AuthLayout>
            <ForgotPassword />
          </AuthLayout>
        </Route>
        <Route>
          <AuthLayout>
            <Login setupChecklistMode={setupChecklistMode} />
          </AuthLayout>
        </Route>
      </Switch>
    </SegmentPageTracking>
  );

  const unpaidRoutes = (
    <SegmentPageTracking isUnauthed={false}>
      <Switch>
        {/* <Route path="/discount/:code">
          <DiscountLink isAuth={false} isUnpaid={true} />
        </Route>
        <Route path="/discount/:frequency/:code">
          <DiscountLink isAuth={false} isUnpaid={true} />
        </Route> */}
        <Route path="/signup" exact>
          <OnboardingSignup {...baseLayoutPageProps} />
        </Route>
        <Route path="/signup/info/:step" exact>
          <Onboarding {...baseLayoutPageProps} />
        </Route>
        <Route path="/signup/international" exact>
          <Onboarding {...baseLayoutPageProps} />
        </Route>
        <Route>
          <Unpaid {...baseLayoutPageProps} />
        </Route>
      </Switch>
    </SegmentPageTracking>
  );

  useEffect(() => {
    if (account?.status === 'onboarding' && lastStepComplete) {
      const show = !hasLoaded || subscription?.status === 'pending' || triggeredStripeConnect;
      setShowSpinner(show);
    } else {
      setShowSpinner(!hasLoaded || triggeredStripeConnect);
    }
  }, [subscription, shop, hasLoaded, account]);

  const content = isAuthed() ? (
    showSpinner ? (
      <Spinner
        label={
          subscription?.status === 'pending'
            ? 'Baking Your Shop...'
            : triggeredStripeConnect
            ? 'Connecting to Stripe...'
            : ''
        }
        show={showSpinner}
        size={'fullscreen'}
      />
    ) : requireValidEmail(me, shop) ? (
      <RequireValidEmail user={me} />
    ) : isUnpaid ? (
      unpaidRoutes
    ) : (
      authenticatedRoutes
    )
  ) : (
    unauthenticatedRoutes
  );

  return (
    <Flibble>
      <RootModal modalType={modalType} modalProps={modalProps} />
      {content}
    </Flibble>
  );
};

export default App;
