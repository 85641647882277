import { Presale, Product, ShopTheme, getPresaleImageUrl, hasSchedule } from '@castiron/domain';
import { getProductStatus } from '@castiron/utils';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { ButtonV2 } from '../Button';
import Pill from '../Pill';
import Typography from '../Typography';

interface Props {
  onClick: () => void;
  presale: Presale;
  themeProps?: ShopTheme;
  timeZone: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    display: 'grid',
    gap: '16px',
    padding: 16,
    borderRadius: props => (props?.themeProps?.shopButtonRoundness == '0px' ? 0 : 12),
    '&:hover': {
      cursor: 'pointer',
    },
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '0.75fr 1fr',
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down(433)]: {
      gridTemplateRows: 'max-content max-content',
      gridTemplateColumns: '1fr',
    },
  },
  image: {
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  imageContainer: {
    borderRadius: props => (props?.themeProps?.shopButtonRoundness == '0px' ? 0 : 12),
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.branding.v2.gray[100],
    height: '100%',
  },
  noPhotoText: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
  },
  presaleTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  productsLeftPill: {
    marginBottom: 12,
    border: props => `1px solid ${theme.branding.v2[props?.themeProps?.shopButtonColor || 'gray'][500]}`,
    color: props => theme.branding.v2[props?.themeProps?.shopButtonColor || 'gray'][500],
    backgroundColor: theme.branding.v2.gray[0],
  },
  shoppingCartIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  statusPill: {
    backgroundColor: theme.branding.v2.gray[0],
    top: 8,
    borderRadius: 15,
    height: 28,
    padding: '4px 8px',
    position: 'absolute',
    left: 8,
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    color: theme.branding.v2.green[500],
  },
  statusPillDecorator: {
    width: 8,
    height: 8,
    marginRight: 4,
  },
  text: {
    color: theme.branding.v2.gray[500],
  },
}));

const PresaleShopCard: React.FC<Props> = (props: Props) => {
  const { onClick, presale, themeProps, timeZone } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const numOfPresaleProductsLeft = presale?.products?.reduce((total, currentProduct) => {
    const productInventory = currentProduct?.unlimitedInventory ? 50 : (currentProduct as Product)?.inventory;
    return total + productInventory;
  }, 0);
  const status = getProductStatus(presale, timeZone);

  if (!['active', 'scheduled'].includes(status)) return null;

  let presaleImageUrl = getPresaleImageUrl(presale, 'mediumVersion');

  const image = presaleImageUrl ? (
    <img src={presaleImageUrl} className={classes.image} alt={presale?.title} />
  ) : (
    <Grid
      className={classes.image}
      container
      justify="center"
      alignItems="center"
      style={{
        padding: 8,
        backgroundColor: theme.branding.v2.gray[100],
      }}
    >
      <Typography className={classes.noPhotoText} variant="body2">
        {presale?.title}
      </Typography>
    </Grid>
  );

  const statusPillStyles =
    status === 'active'
      ? { color: theme.branding.v2.green[500] }
      : status === 'scheduled'
      ? { color: theme.branding.v2.yellow[900] }
      : {};

  const statusPill = (
    <Grid container className={classes.statusPill} justify="center" alignItems="center" style={statusPillStyles}>
      <FiberManualRecordIcon className={classes.statusPillDecorator} />
      <Typography variant="caption" style={statusPillStyles}>
        {status === 'active' ? 'Live' : 'Coming Soon'}
      </Typography>
    </Grid>
  );

  const formatDate = (date: Moment, dateFormat) => {
    return date.tz(timeZone).format(dateFormat);
  };
  const getScheduleText = () => {
    const shopPageEndTimeFormat = 'MMM D, YYYY [at] h:mm A z';
    const startDateMoment = momentTimezone.unix(presale?.schedule?.startTime);
    const endDateMoment = momentTimezone.unix(presale?.schedule?.endTime);
    return `Sale ${status === 'active' ? 'ends' : 'starts'} ${formatDate(status === 'active' ? endDateMoment : startDateMoment, shopPageEndTimeFormat)}`;
  };

  const useCountdown = (targetDate: number, isSeconds?: boolean, returnSeconds?: boolean) => {
    const formattedDate = isSeconds ? targetDate * 1000 : targetDate;

    //running in milliseconds
    const countDownDate = momentTimezone(formattedDate)
      .tz(timeZone)
      .valueOf();

    const [countDown, setCountDown] = useState(countDownDate - momentTimezone().valueOf());

    useEffect(() => {
      const repInterval = returnSeconds ? 1000 : 60000;
      const interval = setInterval(() => {
        setCountDown(countDownDate - momentTimezone().valueOf());
      }, repInterval);

      return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
  };

  const getReturnValues = (countDown: number) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(countDown / (1000 * 60 * 60));
    const mins = Math.floor(countDown / (1000 * 60));
    const secs = Math.floor(countDown / 1000);

    const dd = days;
    const hh = hours - days * 24;
    const mm = mins - hours * 60;
    const ss = secs - mins * 60;

    return [dd, hh, mm, ss];
  };

  const presaleCountdown = () => {
    if (!hasSchedule(presale)) return null;

    const [days, hours, minutes] = useCountdown(
      status === 'active' ? presale?.schedule?.endTime : presale?.schedule?.startTime,
      true,
      false,
    );
    const countdown = { Days: days, Hours: hours, Minutes: minutes };
    return (
      <Grid container item direction="column" wrap="nowrap" style={{ gap: '8px', marginBottom: 12 }}>
        <Typography variant="body2">Presale {status === 'active' ? 'Ends' : 'Launches'} in</Typography>
        <Grid container item wrap="nowrap" justify="space-between">
          {['Days', 'Hours', 'Minutes'].map((unit, index) => (
            <Grid
              key={`countdown-${index}`}
              container
              item
              direction="column"
              alignContent="center"
              style={{ textAlign: 'center', width: 'fit-content' }}
            >
              <Typography variant="h6">{countdown[unit]}</Typography>
              <Typography variant="caption" style={{ fontWeight: 400 }}>
                {countdown[unit] === 1 ? unit.substring(0, unit.length - 1) : unit}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      className={classes.container}
      onClick={onClick}
      style={
        status === 'scheduled'
          ? { border: `1px solid ${theme.branding.v2.gray[500]}` }
          : { backgroundColor: themeProps?.themeColors?.accent?.secondary || theme.branding.v2.gray[200] }
      }
    >
      <Grid
        container
        item
        className={classes.imageContainer}
        style={!presaleImageUrl ? { backgroundColor: theme.branding.v2.gray[100] } : {}}
      >
        {image}
        {statusPill}
      </Grid>
      <Grid container item direction="column" wrap="nowrap" justify="space-between">
        <Grid container item direction="column" wrap="nowrap">
          <Typography variant="h6" className={classes.presaleTitle}>
            {presale?.title}
          </Typography>
          {hasSchedule(presale) ? (
            <Typography className={classes.text} variant="body2" style={{ marginBottom: 12 }}>
              {getScheduleText()}
            </Typography>
          ) : (
            <Typography className={classes.text} variant="body2" style={{ marginBottom: 12 }}>
              {presale.description}
            </Typography>
          )}
          {presale?.showLowInventoryCount && numOfPresaleProductsLeft < 30 && status === 'active' && (
            <Pill
              content={`${numOfPresaleProductsLeft} Item${numOfPresaleProductsLeft === 1 ? '' : 's'} Left`}
              className={classes.productsLeftPill}
            />
          )}
        </Grid>
        <Grid container item direction="column" wrap="nowrap">
          {presaleCountdown()}
          {status === 'active' && (
            <ButtonV2 variant="contained">
              <ShoppingCartOutlinedIcon className={classes.shoppingCartIcon} />
              Shop Now
            </ButtonV2>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PresaleShopCard;
