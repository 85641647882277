import React, { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { FormControl, FormLabel, Grid, makeStyles, MenuItem, Select, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Theme } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useFormikContext } from 'formik';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  FrontendTransactionState,
  Order,
  ShippingInfo,
  TimePeriod,
  FulfillmentOptionSchedule,
  Address,
} from '@castiron/domain';
import { Input, Typography, Button, CollapsableCard, Checkbox, BusinessAddressInput } from '@castiron/components';
import { defaultTimeZone } from '@castiron/utils';
import { openModal } from '../../../store/reducers/modalConductor';
import Tooltip from '../../Tooltip';
import AddressAddAndDisplay from '../../AddressAddAndDisplay';

type Props = {
  order: Order;
  shippingInfo: ShippingInfo;
  isPaid: boolean;
  state: FrontendTransactionState;
  isInvoice: boolean;
  defaultExpanded?: boolean;
};

const FULFILLMENT_TYPES = ['pickup', 'delivery', 'shipping'];

const useStyles = makeStyles((theme: Theme) => ({
  addTime: {
    marginTop: 8,
    '& :hover': {
      cursor: 'pointer',
    },
    '& span': {
      color: theme.branding.v2.blue[500],
      fontWeight: 600,
    },
  },
  apply: {
    '& span': {
      color: theme.branding.v2.blue[900],
    },
    padding: 0,
  },
  checkbox: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormControlLabel-label': {
        paddingRight: 0,
      },
    },
  },
  closeTime: {
    width: 20,
    height: 20,
    marginLeft: 6,
  },
  disabled: {
    backgroundColor: theme.branding.v2.gray[100],
    borderRadius: 12,
    '& .MuiInputBase-input.Mui-disabled:hover': {
      cursor: 'default',
    },
  },
  cardContent: {
    gap: 24,
  },
  error: {
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    '& label': {
      color: '#000',
      fontWeight: 700,
      fontSize: '14px',
      marginBottom: 0,
    },
  },
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  hyphen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 4px 0px',
  },
  pickerMargin: {
    marginTop: 4,
  },
  requestedInformation: {
    backgroundColor: theme.branding.v2.blue[50],
    padding: '8px 12px',
    borderRadius: '8px',
    width: '100%',
  },
  requestedValue: {
    fontWeight: 700,
  },
  selectBox: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  timePicker: {
    width: '100%',
    '& input:hover': {
      cursor: 'pointer',
    },
    '& .MuiTextField-root': {
      width: '100%',
      '& input:hover': {
        borderBottom: 'none',
      },
      '& input::placeholder': {
        color: theme.branding.v2.blue[500],
        opacity: 1,
      },
      '& input:disabled::placeholder': {
        color: theme.branding.v2.gray[400],
      },
    },

    '& .MuiInput-underline::before': {
      borderBottom: 'none',
    },
  },
  tooltip: {
    marginBottom: '-3px',
    marginLeft: 4,
  },
  truncate: {
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all',
    },
  },
}));

const QuoteFulfillment: React.FC<Props> = (props: Props) => {
  const { order, isPaid, shippingInfo, state, isInvoice, defaultExpanded = false } = props;
  const { values, setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [selectedType, setSelectedType] = useState<string>('');
  const [fulfillmentTypes, setFulfillmentTypes] = useState<string[]>(FULFILLMENT_TYPES);
  const [fulfillmentName, setFulfillmentName] = useState<string>('Fulfillment');
  const [date, setDate] = useState<TimePeriod>(null);
  const [requestedType, setRequestedType] = useState<string>(order?.requestedFulfillment?.type);
  const [requestedDate, setRequestedDate] = useState<number>(order?.requestedFulfillment?.date || null);
  const [schedule, setSchedule] = useState<FulfillmentOptionSchedule>();
  const [dateTimeError, setDateTimeError] = useState('');
  const [day, setDay] = useState<moment.Moment | null>(null);
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;

  useEffect(() => {
    if (order?.fulfillmentOption) {
      setSelectedType(order.fulfillmentOption.type);
      setFulfillmentName(order.fulfillmentOption.type ? _.capitalize(order.fulfillmentOption.type) : 'Fulfillment');

      if (!_.isEmpty(order.fulfillmentOption.schedule?.dates)) {
        const date = order.fulfillmentOption.schedule.dates[0];

        setDate(date);
        setSchedule(order.fulfillmentOption.schedule);
      } else if (order.fulfillmentOption.date) {
        //if there is only a date (which reads as a start time), correctly format the start and end times so they're not random numbers
        const startTime = moment.unix(order.fulfillmentOption.date);
        startTime.hour(12);
        startTime.minute(0);
        startTime.second(0);
        const formattedStart = startTime.unix();
        const endTime = parseInt(startTime.add(30, 'minutes').format('X'));

        const date: TimePeriod = {
          id: nanoid(),
          startTime: formattedStart,
          endTime,
        };

        const schedule: FulfillmentOptionSchedule = {
          id: nanoid(),
          type: 'flexible',
          dates: [date],
        };

        setDate(date);
        setSchedule(schedule);
        setFieldValue('order.fulfillmentOption.schedule', schedule);
      } else {
        const schedule: FulfillmentOptionSchedule = {
          id: nanoid(),
          type: 'flexible',
          dates: [],
        };

        setSchedule(schedule);
        setFieldValue('order.fulfillmentOption.schedule', schedule);
      }
    }

    if (order?.requestedFulfillment) {
      setRequestedDate(order.requestedFulfillment.date);
      setRequestedType(order.requestedFulfillment.type);
    }
  }, [order]);

  useEffect(() => {
    date?.startTime ? setDay(moment.unix(date.startTime).tz(timeZone)) : null;
  }, [date]);

  const setInitialSendPickupReminder = () => {
    if (order.fulfillmentOption?.sendPickupReminderEmail !== undefined) {
      setFieldValue('order.fulfillmentOption.sendPickupReminderEmail', order.fulfillmentOption.sendPickupReminderEmail);
    } else setFieldValue('order.fulfillmentOption.sendPickupReminderEmail', true);
  };

  useEffect(() => {
    selectedType === 'pickup' && setInitialSendPickupReminder();
  }, [selectedType]);

  const handleFulfillmentChange = (type, formikValues) => {
    setSelectedType(type);
    setFulfillmentName(type ? _.capitalize(type) : 'Fulfillment');
    /* shipping info has been pulled out of fulfillmentOption in data,
     * but is maintained in fulfillmentOption in formik form,
     * so set it explicitly here,
     * submission separates them
     */
    const fulfillment = {
      ...order.fulfillmentOption,
      status: order.fulfillmentOption?.status || 'active',
      type,
      /* additional hack to make the hack in edit quote to track user selection work */
      displayName: _.capitalize(type),
      fee: formikValues.fulfillmentOption.fee || 0,
      date: date?.startTime || formikValues.fulfillmentOption.date,
      schedule: schedule || formikValues.fulfillmentOption.schedule,
      notes: formikValues.fulfillmentOption.notes || '',
      address: type === 'pickup' ? shop?.physicalAddress : formikValues.fulfillmentOption.address,
      useBusinessAddress: type === 'pickup',
    };

    setFieldValue('order.fulfillmentOption', fulfillment);
  };

  const handleDayChange = (day: moment.Moment | null) => {
    if (day && day.isValid()) {
      setDay(day);
      let newStartTime;
      let newEndTime;
      let newTimePeriod;

      // if a startTime exists, we're editing an existing date
      // merge the date and time
      if (date?.startTime) {
        newStartTime = moment.unix(date.startTime).tz(timeZone);
        newStartTime.year(day.year());
        newStartTime.month(day.month());
        newStartTime.date(day.date());
      } else {
        // if no startTime exists, we're adding a new date at 12:00pm
        newStartTime = moment(day);
        newStartTime.hour(12);
        newStartTime.minute(0);
        newStartTime.second(0);
        newStartTime.millisecond(0);
      }

      if (date?.endTime) {
        newEndTime = moment.unix(date.endTime).tz(timeZone);
        newEndTime.year(day.year());
        newEndTime.month(day.month());
        newEndTime.date(day.date());
      } else {
        newEndTime = moment(day);
        newEndTime.hour(12);
        newEndTime.minute(30);
        newEndTime.second(0);
        newEndTime.millisecond(0);
      }

      if (date?.startTime) {
        newTimePeriod = {
          id: date.id,
          startTime: newStartTime.tz(timeZone, true).unix(),
          endTime: newEndTime.tz(timeZone, true).unix(),
        };
      } else {
        newTimePeriod = {
          id: nanoid(),
          startTime: newStartTime.tz(timeZone, true).unix(),
          endTime: newEndTime.tz(timeZone, true).unix(),
        };
      }

      if (!schedule) {
        const newSchedule: FulfillmentOptionSchedule = {
          id: nanoid(),
          type: 'flexible',
          dates: [newTimePeriod],
        };

        setSchedule(newSchedule);
        setFieldValue('order.fulfillmentOption.schedule', newSchedule);
      } else {
        setSchedule({
          ...schedule,
          dates: [newTimePeriod],
        });
        setFieldValue('order.fulfillmentOption.schedule.dates[0]', newTimePeriod);
      }

      setDate(newTimePeriod);
      setFieldValue('order.fulfillmentOption.date', newTimePeriod.startTime);
    }
  };

  const handleTimeChange = (time: moment.Moment | null, field: string) => {
    if (time && time.isValid()) {
      const newTimePeriod = {
        id: date.id,
        startTime: date.startTime,
        endTime: date.endTime,
      };

      if (field === 'startTime') {
        const newStartTime = moment.unix(date.startTime);
        newStartTime.hour(time.hour());
        newStartTime.minute(time.minute());
        newStartTime.tz(timeZone, true);
        newTimePeriod.startTime = newStartTime.unix();
        day ? setDay(newStartTime) : null;

        /* make end time 30 mins after new start time */
        const newEndTime = newStartTime.add(30, 'minutes');
        newTimePeriod.endTime = newEndTime.unix();
      } else if (field === 'endTime') {
        const newEndTime = moment.unix(date.endTime);
        newEndTime.hour(time.hour());
        newEndTime.minute(time.minute());
        newEndTime.tz(timeZone, true);
        newTimePeriod.endTime = newEndTime.unix();
        day ? setDay(newEndTime) : null;
      }

      if (newTimePeriod.startTime < newTimePeriod.endTime) {
        setSchedule({
          ...schedule,
          dates: [newTimePeriod],
        });

        setDate(newTimePeriod);
        setDateTimeError('');

        setFieldValue('order.fulfillmentOption.date', newTimePeriod.startTime);
        setFieldValue('order.fulfillmentOption.schedule.dates[0]', newTimePeriod);
      } else {
        setDateTimeError('Time not saved. Start time must be before end time.');
      }
    }
  };

  const handleToggleTime = type => {
    const newSchedule: FulfillmentOptionSchedule = {
      ...schedule,
      type,
    };

    setSchedule(newSchedule);
    setFieldValue('order.fulfillmentOption.schedule.type', type);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleSendPickupEmailChange = () => {
    setFieldValue(
      'order.fulfillmentOption.sendPickupReminderEmail',
      !values.order.fulfillmentOption.sendPickupReminderEmail,
    );
  };

  const setAddress = async (address: Address) => {
    await setFieldValue('order.fulfillmentOption.address', {
      fullAddress: address?.fullAddress || '',
      addressLine1: address?.addressLine1 || '',
      addressLine2: address?.addressLine2 || '',
      city: address?.city || '',
      region: address?.region || '',
      regionName: address?.regionName || '',
      country: address?.country || '',
      postalCode: address?.postalCode || '',
    });
  };

  const handleBusinessAddressCheckbox = async () => {
    const address = !values.order.fulfillmentOption.useBusinessAddress ? shop?.physicalAddress : undefined;
    await setFieldValue(
      'order.fulfillmentOption.useBusinessAddress',
      !values.order.fulfillmentOption.useBusinessAddress,
    );
    setAddress(address);
  };

  return (
    <CollapsableCard
      title="Fulfillment"
      handleExpand={toggleExpand}
      expanded={expanded}
      noScroll
      contentClassName={classes.cardContent}
    >
      <Grid container item style={{ gap: 24 }}>
        <FormControl className={classes.formControl} variant="outlined">
          <Grid container item xs={12} alignItems="flex-end">
            <FormLabel required>Fulfillment method</FormLabel>
            <Tooltip
              tooltipClass={classes.tooltip}
              title="How will you get this order to your customer? Customers can only request a method that you currently have active in your storefront, but you can select any method."
            />
          </Grid>
          <Select
            displayEmpty={true}
            value={selectedType}
            onChange={event => handleFulfillmentChange(event.target.value, values.order)}
            placeholder="Select a method"
            variant="outlined"
            className={classes.selectBox}
            disabled={isPaid}
          >
            {(state === 'new' || state === 'draft') && !isInvoice && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {fulfillmentTypes.map(type => (
              <MenuItem className={classes.selectOptionContainer} key={type} value={type}>
                <Typography className={classes.selectOption} variant="body1">
                  {_.capitalize(type)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          {requestedType && (
            <Grid className={classes.requestedInformation} container direction="row" justify="space-between">
              <Typography variant="body2">
                Customer requested: <span className={classes.requestedValue}>{_.capitalize(requestedType)}</span>
              </Typography>
              {requestedType !== selectedType && !isPaid && (
                <Button
                  size="small"
                  onClick={() => {
                    handleFulfillmentChange(requestedType, values.order);
                    setFulfillmentName(_.capitalize(requestedType));
                  }}
                  className={classes.apply}
                >
                  <Typography variant="button">Apply</Typography>
                </Button>
              )}
            </Grid>
          )}
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined">
          <Grid container item xs={12} alignItems="flex-end">
            <FormLabel required>{fulfillmentName} date</FormLabel>
            <Tooltip
              tooltipClass={classes.tooltip}
              title="The date this order needs to be in your customer’s hands. This may not always be the date of the event."
            />
          </Grid>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid>
              <KeyboardDatePicker
                autoOk
                disablePast={false}
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="date-picker-inline"
                inputVariant="outlined"
                value={day}
                style={{ width: '100%' }}
                onChange={date => handleDayChange(date.tz(timeZone, true))}
                KeyboardButtonProps={{
                  'aria-label': 'Fulfillment due date',
                }}
                disabled={isPaid}
              />
              {requestedDate && (
                <Grid
                  className={classes.requestedInformation}
                  container
                  direction="row"
                  justify="space-between"
                  alignContent="center"
                >
                  <Typography variant="body2">
                    Customer requested:{' '}
                    <span className={classes.requestedValue}>
                      {moment(requestedDate, 'X')
                        .tz(timeZone)
                        .format('L')}
                    </span>
                  </Typography>
                  {moment(requestedDate, 'X')
                    .tz(timeZone)
                    .format('L') !==
                    moment(date?.startTime, 'X')
                      .tz(timeZone)
                      .format('L') &&
                    !isPaid && (
                      <Button
                        size="small"
                        onClick={() => handleDayChange(moment(requestedDate, 'X').tz(timeZone))}
                        className={classes.apply}
                      >
                        <Typography variant="button">Apply</Typography>
                      </Button>
                    )}
                </Grid>
              )}
            </Grid>
            {(fulfillmentName == 'Pickup' || fulfillmentName == 'Delivery') &&
              (!!schedule && schedule?.type === 'fixed' ? (
                <Grid container item xs={12} wrap="nowrap">
                  <Grid container item xs={isPaid ? 12 : 11} wrap="nowrap" style={{ marginTop: 16 }}>
                    <Grid item className={classes.timePicker} xs={6}>
                      <FormLabel className={classes.truncate}>{fulfillmentName} Start Time</FormLabel>
                      <TimePicker
                        margin="normal"
                        minutesStep={5}
                        id="time-picker"
                        inputVariant="outlined"
                        value={date?.startTime ? moment.unix(date.startTime).tz(timeZone) : null}
                        onChange={time => handleTimeChange(time, 'startTime')}
                        className={clsx([classes.pickerMargin, !date?.startTime && classes.disabled])}
                        disabled={!date?.startTime || isPaid}
                      />
                    </Grid>
                    <Typography className={classes.hyphen}>-</Typography>
                    <Grid item className={classes.timePicker} xs={6}>
                      <FormLabel className={classes.truncate}>{fulfillmentName} End Time</FormLabel>
                      <TimePicker
                        margin="normal"
                        minutesStep={5}
                        id="time-picker"
                        inputVariant="outlined"
                        value={date?.endTime ? moment.unix(date.endTime).tz(timeZone) : null}
                        onChange={time => handleTimeChange(time, 'endTime')}
                        className={clsx([classes.pickerMargin, !date?.startTime && classes.disabled])}
                        disabled={!date?.startTime || isPaid}
                      />
                    </Grid>
                  </Grid>
                  {!isPaid && (
                    <Grid container item xs={1} alignItems="center" style={{ marginTop: 30 }}>
                      <IconButton className={classes.closeTime} onClick={() => handleToggleTime('flexible')}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ) : schedule && schedule?.type === 'flexible' && !isPaid ? (
                <Grid onClick={() => handleToggleTime('fixed')} className={classes.addTime}>
                  <Typography variant="button">+ Add {fulfillmentName} Time</Typography>
                </Grid>
              ) : (
                <></>
              ))}
          </MuiPickersUtilsProvider>
          {dateTimeError && (
            <Typography variant="caption" color="error" className={classes.error}>
              {dateTimeError}
            </Typography>
          )}
        </FormControl>
        {(!isPaid || shippingInfo?.recipientName) && (
          <FormControl className={classes.formControl} variant="outlined">
            <Grid container item xs={12} alignItems="flex-end">
              <FormLabel>{fulfillmentName} recipient</FormLabel>
              <Tooltip
                tooltipClass={classes.tooltip}
                title="If the contact for pickup, delivery, or shipping is different from your contact for the order, add them here for easier fulfillment."
              />
            </Grid>
            <Input
              type="text"
              value={values.order.fulfillmentOption.recipient}
              name="order.fulfillmentOption.recipient"
              placeholder="Name if not Customer name"
              variant="outlined"
              disabled={isPaid}
            />
          </FormControl>
        )}
        <FormControl className={classes.formControl} variant="outlined" required>
          {selectedType === 'pickup' && (
            <>
              <FormLabel>{fulfillmentName} address</FormLabel>
              <Grid style={{ margin: '8px 0px' }}>
                <Checkbox
                  checked={values.order.fulfillmentOption.useBusinessAddress}
                  onChange={handleBusinessAddressCheckbox}
                  name="order.fulfillmentOption.useBusinessAddress"
                  checkboxClass={classes.checkbox}
                  label={<Typography variant="body2">Use Business Address</Typography>}
                  disabled={isPaid}
                />
              </Grid>
            </>
          )}
          {values.order.fulfillmentOption.useBusinessAddress ? (
            <AddressAddAndDisplay
              address={values.order.fulfillmentOption.address}
              context="quote"
              onSuccess={address => setAddress(address)}
            />
          ) : (
            <BusinessAddressInput
              label={selectedType !== 'pickup' ? `${fulfillmentName} address` : undefined}
              name="order.fulfillmentOption.address"
              removeBusinessInfo
            />
          )}
        </FormControl>
        {(!isPaid || values?.order?.fulfillmentOption?.notes) && (
          <>
            <FormControl className={classes.formControl} variant="outlined">
              <FormLabel>{fulfillmentName} notes</FormLabel>
              <Input
                type="text"
                value={values.order.fulfillmentOption.notes}
                name="order.fulfillmentOption.notes"
                placeholder="Delivery before noon"
                variant="outlined"
                disabled={isPaid}
                multiline
              />
            </FormControl>
          </>
        )}
        <Grid
          container
          item
          direction="column"
          style={values.order.fulfillmentOption.type !== 'pickup' ? { display: 'none' } : {}}
        >
          <Grid container item direction="row">
            <Typography variant="subtitle2">⚡️ Automated Reminders</Typography>
            <Tooltip
              tooltipClass={classes.tooltip}
              title="Let us do the work for you! Leave this setting on to automatically send email reminders to your customers."
            />
          </Grid>
          <Checkbox
            checked={!!values.order.fulfillmentOption.sendPickupReminderEmail}
            onChange={() => handleSendPickupEmailChange()}
            name="order.fulfillmentOption.sendPickupReminderEmail"
            checkboxClass={classes.checkbox}
            label={
              <Typography variant="body2">
                Send an automated pickup reminder to your customer 24 hours before pickup time.
              </Typography>
            }
            disabled={isPaid}
          />
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};

export default QuoteFulfillment;
