import { SvgIcon, Typography } from '@castiron/components';
import { Asset } from '@castiron/domain';
import { Box, ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormValues } from '.';
import { productRepository } from '../../../../domain';

interface Props {
  productId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '24px',
  },
  dragHandle: {
    color: theme.branding.v2.gray[900],
  },
  dragDots: {
    '& svg > path': {
      fill: theme.branding.v2.gray[800],
    },
  },
  draggableImageCard: {
    background: theme.branding.v2.gray[0],
    height: 102,
    padding: '16px',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 16,
    marginBottom: 8,
  },
  draggableImageBox: {
    height: 86,
    width: 86,
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      height: 86,
      width: 86,
    },
  },
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '16px',
  },
  imageContainer: {
    height: '172px',
    width: '172px',
    borderRadius: '16px',
  },
  primaryLabel: {
    padding: '4px 12px',
    borderRadius: '100px',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
}));

function reorderPhotos<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

const RearrangePhotos: React.FC<Props> = (props: Props) => {
  const { productId } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [localImages, setLocalImages] = useState(values.images);

  const onDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    const currentImages = (await productRepository.get(productId))?.images;
    const sortedPhotos = reorderPhotos<any>(currentImages, result.source.index, result.destination.index);
    setFieldValue('images', sortedPhotos);
    setLocalImages(sortedPhotos);

    await productRepository.updateProps(productId, {
      images: sortedPhotos,
    });
  };

  useEffect(() => {
    /* need to update this accordingly when images are added or deleted on hosting page */
    setLocalImages(values.images);
  }, [values]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box className={classes.dialogContent}>
        <Grid container item>
          <Droppable droppableId="productPhotos" type="PHOTOS">
            {(provided, snapshot) => (
              <Grid item container xs={12} alignItems="center" direction="column" wrap="nowrap" ref={provided.innerRef}>
                {localImages.map((productImage: Asset, index: number) => (
                  <Draggable draggableId={productImage.id} index={index} key={productImage.id}>
                    {(provided, snapshot) => (
                      <Grid
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        container
                        item
                        key={productImage.id}
                        className={classes.draggableImageCard}
                        justify="space-between"
                        alignItems="center"
                        xs={12}
                        sm={8}
                        wrap="nowrap"
                      >
                        <Grid container item style={{ gap: '8px' }} alignItems="center">
                          <Grid container item className={classes.draggableImageBox}>
                            <img
                              src={productImage?.smallVersion?.downloadUrl || productImage?.downloadUrl}
                              className={classes.image}
                            />
                          </Grid>
                          {index === 0 && (
                            <Typography variant="caption" className={classes.primaryLabel}>
                              Primary
                            </Typography>
                          )}
                        </Grid>
                        <ButtonBase {...provided.dragHandleProps} className={classes.dragHandle}>
                          <SvgIcon className={classes.dragDots}>
                            <DragIndicator />
                          </SvgIcon>
                        </ButtonBase>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </Grid>
      </Box>
    </DragDropContext>
  );
};

export default RearrangePhotos;
