import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { nanoid } from '@reduxjs/toolkit';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Banner, ButtonV2, Checkbox, Typography } from '@castiron/components';
import { FulfillmentOption, fulfillmentTypeDisplayName } from '@castiron/domain';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import ModalWrapper from '../../../../RootModal/ModalWrapper';
import DateTimePickers from './DateTimePickers';

const useStyles = makeStyles((theme: Theme) => ({
  addDateButton: {
    marginTop: 24,
    padding: 16,
  },
  container: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '24px',
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  deleteButton: {
    color: theme.branding.v2.red[500],
  },
  header: {
    padding: '22px 24px',
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  title: {
    marginBottom: 6,
  },
}));

export type Props = {
  open: boolean;
  setOpen: (boolean) => void;
  count?: number;
  isAdd?: boolean;
};

const AddDateTimeOptionModal: React.FC<Props> = (props: Props) => {
  const { open, setOpen, count, isAdd = false } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const formik = useFormikContext<FulfillmentOption>();
  const pageType = formik.values.type;
  const index = count ? count - 1 : 0;
  const [showError, setShowError] = useState<boolean>(false);

  const handleDelete = async () => {
    setShowError(false);
    const newDates = [...formik.values.schedule.dates];
    newDates.splice(index, 1);
    await formik.setFieldValue('schedule.dates', newDates);
    setOpen(false);
  };

  const handleClose = (): void => {
    setShowError(false);
    if (isAdd) {
      handleDelete();
    }
    setOpen(false);
  };

  const handleAdd = (): void => {
    setShowError(false);
    if (formik.values?.schedule?.dates[index]?.startTime && formik.values?.schedule?.dates[index]?.endTime) {
      if (!formik.values?.schedule?.dates[index]?.id) {
        formik.setFieldValue(`schedule.dates[${index}].id`, nanoid());
      }
      setOpen(false);
    } else {
      setShowError(true);
    }
  };

  return (
    <ModalWrapper fullScreen={isMobile} size="xl" show={open}>
      <DialogTitle className={classes.header}>
        <Typography variant="h6">{`${fulfillmentTypeDisplayName(pageType, 'short')} ${count}`}</Typography>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Grid container direction="column" item xs={12}>
          {showError && (
            <Grid style={{ marginBottom: 5 }}>
              <Banner variant="error">
                <Typography variant="body2">Please select a date and time</Typography>
              </Banner>
            </Grid>
          )}
          <Typography variant="subtitle2" className={classes.title}>
            Date/Time
          </Typography>
          <DateTimePickers
            date={formik.values?.schedule?.dates[index]}
            index={index}
            key={index}
            setShowError={setShowError}
          />

          <Grid
            container
            item
            justify={isMobile ? 'center' : 'flex-start'}
            alignContent={isMobile ? 'center' : 'flex-start'}
            style={{ marginTop: 12 }}
          >
            <Checkbox
              label={<Typography variant="body1">Repeat this option every week</Typography>}
              checked={formik?.values?.schedule?.dates[index]?.repeatWeekly}
              onChange={() => {
                formik.setFieldValue(
                  `schedule.dates[${index}].repeatWeekly`,
                  !formik?.values?.schedule?.dates[index]?.repeatWeekly,
                );
              }}
            />
          </Grid>
          <Grid
            container
            item
            justify={isMobile ? 'center' : 'flex-start'}
            alignContent={isMobile ? 'center' : 'flex-start'}
          >
            <Typography variant="body2" style={{ width: isMobile ? 240 : 682, paddingLeft: isMobile ? 5 : 31 }}>
              For repeating options, shoppers will only be able to select a date up to a month in the future to optimize
              the shopping experience.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify={isAdd ? 'flex-end' : 'space-between'}>
          {!isAdd && (
            <Grid item>
              <ButtonV2 className={classes.deleteButton} variant="outlined" onClick={handleDelete}>
                Delete
              </ButtonV2>
            </Grid>
          )}
          <Grid item>
            <ButtonV2 variant="outlined" onClick={handleClose}>
              Discard
            </ButtonV2>
            <ButtonV2 variant="contained" onClick={handleAdd} style={{ marginLeft: 8 }}>
              Add
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogActions>
    </ModalWrapper>
  );
};

export default AddDateTimeOptionModal;
