export type TemplateType =
  | '4th-of-july-announcement'
  | '4th-of-july-customs'
  | '4th-of-july-reminder'
  | '4th-of-july-engagement'
  | '4th-of-july-shop'
  | '4th-of-july-last-call'
  | 'fathers-day-announcement'
  | 'fathers-day-customs'
  | 'fathers-day-reminder'
  | 'fathers-day-engagement'
  | 'fathers-day-shop'
  | 'fathers-day-last-call'
  | 'graduation-announcement'
  | 'graduation-reminder'
  | 'graduation-feature'
  | 'graduation-last-call'
  | 'back-to-school-announcement'
  | 'back-to-school-reminder'
  | 'back-to-school-shop'
  | 'back-to-school-customs'
  | 'back-to-school-last-call'
  | 'sms-signup'
  | 'new-product'
  | 'custom-order-available'
  | 'new-presale'
  | 'ticketed-event-announcement'
  | 'fall-announcement'
  | 'fall-reminder'
  | 'shop-fall'
  | 'fall-customs'
  | 'last-call-fall'
  | 'halloween-announcement'
  | 'halloween-reminder'
  | 'shop-halloween'
  | 'halloween-customs'
  | 'last-call-halloween'
  | 'thanksgiving-announcement'
  | 'thanksgiving-reminder'
  | 'shop-thanksgiving'
  | 'thanksgiving-customs'
  | 'last-call-thanksgiving'
  | 'black-friday-announcement-1'
  | 'black-friday-announcement-2'
  | 'black-friday-announcement-3'
  | 'small-business-saturday-1'
  | 'small-business-saturday-2'
  | 'small-business-saturday-3'
  | 'cyber-monday-announcement-1'
  | 'cyber-monday-announcement-2'
  | 'cyber-monday-announcement-3'
  | 'christmas-announcement'
  | 'christmas-reminder'
  | 'shop-christmas'
  | 'christmas-customs'
  | 'last-call-christmas'
  | 'hanukkah-announcement'
  | 'hanukkah-reminder'
  | 'kwanzaa-announcement'
  | 'kwanzaa-reminder'
  | 'nye-announcement'
  | 'shop-nye'
  | 'last-call-nye'
  | 'valentines-announcement'
  | 'shop-valentines'
  | 'last-call-valentines'
  | 'big-game-announcement'
  | 'big-game-new-products'
  | 'big-game-last-call';

export interface Template {
  eventName?: string;
  eventTag?: string;
  pageTitle: string;
  pageTitleMobile?: string;
  step1TitleUnselected: string;
  step1TitleSelectedPrefix?: string;
  subjectLine: string;
  bodyText: string;
  buttonText: string;
  postSendModalTitlePrefix: string;
  trackEventName: string;
  showCustomProductsOnly: boolean;
  showTicketedEvents?: boolean;
  utmCampaign?: string;
}

export const templates = {
  '4th-of-july-announcement': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Announcement',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎉 Celebrate July 4th with a Dash of Sweetness!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you geared up for a patriotic celebration filled with scrumptious delights? 🎆 Make this July 4th truly unforgettable with our mouthwatering small batch treats!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Announcement',
    showCustomProductsOnly: false,
  },
  '4th-of-july-customs': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Customs',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Custom Goods for Independence Day!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking to add a personal touch to your July 4th celebration? My shop is now accepting custom orders for small-batch artisanal foods tailored to your patriotic party theme!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Customs',
    showCustomProductsOnly: true,
  },
  '4th-of-july-reminder': {
    eventName: "Father's Day",
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Reminder',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'A Burst of Patriotic Flavor! 🧨',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you prepared to add a dash of All-American sweetness to your July 4th festivities? ✨ Make this Independence Day one to remember with delectable artisanal goodies!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Reminder',
    showCustomProductsOnly: false,
  },
  '4th-of-july-engagement': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Engagement',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎇 Elevate Your July 4th Celebration!',
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hi there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>As July 4th approaches, it's time to plan your festivities with some delectable treats! My online shop is stocked with an array of mouthwatering goods that are perfect for adding a memorable touch to your Independence Day celebration.</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Engagement',
    showCustomProductsOnly: false,
  },
  '4th-of-july-shop': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Shop',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: "🎇 Time's Ticking! Ready for July 4th?",
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hello there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Just as fast as the fireworks fizzle - don't miss out on these stellar foods! Place your order now to ensure delivery in time for July 4th festivities. Time's running out!</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Shop',
    showCustomProductsOnly: false,
  },
  '4th-of-july-last-call': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July: Last Call',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎆 Last Call for July 4th Orders!',
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hi there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Don't miss out on adding some extra sweetness to your July 4th holiday - place your order now before it's too late!</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: '4th of July Email',
    trackEventName: '4th of July: Last Call',
    showCustomProductsOnly: false,
  },
  'fathers-day-announcement': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Announcement',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Father’s Day: Are You Ready?',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you ready for a dose of special sweetness for the dads and dad figures in your life? ⛳ Make Father’s Day one to remember with delicious small batch goodies!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Announcement',
    showCustomProductsOnly: false,
  },
  'fathers-day-customs': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Customs',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Dad = Too Sweet! Are you ready?',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time is running out! Be sure to get your custom orders in time for Father’s Day on June 16th. For best results, we recommend a June 9th deadline!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Give your dad, brother, husband, grandfather, stepdad, or dog dad a memorable Father’s Day with specialty foods!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Customs',
    showCustomProductsOnly: true,
  },
  'fathers-day-reminder': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Reminder',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'How Sweet Father’s Day Is!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Just checking in - do you have something thoughtful planned for the father in your life? Nothing says indulgence like heartfelt creations. Ties collect dust, golf clubs sit in storage, but great baked goods make great memories and are a unique and thoughtful gift! 🍪</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Reminder',
    showCustomProductsOnly: false,
  },
  'fathers-day-engagement': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Engagement',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Spoil Dad this Father’s Day!',
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hi there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>As Father's Day approaches, we're excited to offer you the perfect opportunity to show your love and appreciation for all the dads and dad-figures in your life!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>That's why we've curated a delightful selection of artisanal food goods that are sure to make his day memorable.</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Engagement',
    showCustomProductsOnly: false,
  },
  'fathers-day-shop': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Shop',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: "Save the Day with Treats for Father's Day!",
    bodyText:
      "<p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Hello there!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>With Father's Day fast approaching, are you feeling the pressure to find the perfect gift?</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>Fear not! We're here to save the day with our lineup of artisanal delights tailored to make every dad feel like a superhero!</span></p><p><br></p><p><span style='background-color: transparent; color: rgb(0, 0, 0);'>It's time to soar into action and secure your order before Father's Day arrives in less than a week!</span></p>",
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Shop',
    showCustomProductsOnly: false,
  },
  'fathers-day-last-call': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: 'Father’s Day: Last Call',
    pageTitleMobile: 'Father’s Day',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Super Dad = Super Treats!',
    bodyText:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time’s a-ticking! 🕓 do you have your gifts ready for Father’s Day?</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Have no fear, artisanal foods are here! We’re your super heroes, offering unique culinary experiences for the dads and dad-figures in your life!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time to swoop in and place your order since Father’s Day is less than one week away!</span></p>',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Father’s Day Email',
    trackEventName: 'Father’s Day: Last Call',
    showCustomProductsOnly: false,
  },
  'graduation-announcement': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Announcement Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Time for Pomp and Circumstance! 🎓',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Graduation season is upon us! Don’t miss the grade - or the opportunity to celebrate the grad in your life with delicious artisanal goodies!</span></p>`,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Announcement',
    showCustomProductsOnly: false,
  },
  'graduation-reminder': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Reminder Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Make Grad Parties Memorable! 🎉',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">We bet you have at least one grad party on the books this spring - whether it's your own family member’s, friend’s, neighbor’s, or heck, maybe it’s your own!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Graduation happens across all ages and walks of life - from kindergarten all the way to a doctorate! 🎓</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Make the occasion memorable with delicious and eye-catching creations!</span></p>
    `,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Reminder',
    showCustomProductsOnly: false,
  },
  'graduation-feature': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Feature Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Time is Running Out!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Time is running out to make the grade 📝 with custom artisanal creations!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Get your orders in before the sun sets on graduation season!</span></p>
    `,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Feature',
    showCustomProductsOnly: false,
  },
  'graduation-last-call': {
    eventName: 'Graduation',
    eventTag: 'Graduation',
    pageTitle: 'Graduation Last Call Email',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Extra Credit: Let’s Get Ordered! ',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Tick-tock! The graduation clock is winding down, and we don't want you to miss out on your slice of the celebration cake (or cupcakes, or cookies... you get the idea)!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">So, what are you waiting for? Don't let procrastination be your major!</span></p>
    `,
    buttonText: 'Shop Graduation',
    postSendModalTitlePrefix: 'Graduation Email',
    trackEventName: 'Graduation Last Call',
    showCustomProductsOnly: false,
  },
  'back-to-school-announcement': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Announcement',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '📣 Delicious and Memorable School Year Ahead!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Pencils are packed, crayons are organized, backpacks are by the door. Forgetting something? Set the tone for the new school year with our mouthwatering goodies!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Announcement',
    showCustomProductsOnly: false,
  },
  'back-to-school-reminder': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Reminder',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Tick-tock, Back-to-School is Approaching!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">The final bell is about to ring - don’t miss out on ordering back-to-school favorites today! ⌚</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Reminder',
    showCustomProductsOnly: false,
  },
  'back-to-school-shop': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Shop',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Apples = Old News',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Bringing teachers an apple is so old-school - kick things up a notch with small-batch goodies this back-to-school season!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Shop',
    showCustomProductsOnly: false,
  },
  'back-to-school-customs': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Customs',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Three Cheers for Custom Goodies!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Rah, rah! 📣 Sports games and school spirit go hand-in-hand! Gear up for events with custom goodies in team colors to embrace upcoming festivities!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Customs',
    showCustomProductsOnly: true,
  },
  'back-to-school-last-call': {
    eventName: 'Back to School',
    eventTag: 'BackToSchool',
    pageTitle: 'Back-to-School Last Call',
    pageTitleMobile: 'Back-to-School',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Last Call: The final bell is about to ring 🔔',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t let time run out! Order your back-to-school favorites today and create wholesome memories.</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Back-to-School Email',
    trackEventName: 'Back-to-School Last Call',
    showCustomProductsOnly: false,
  },
  'sms-signup': {
    pageTitle: 'Sign Up for SMS',
    pageTitleMobile: 'Sign Up for SMS',
    step1TitleUnselected: '',
    subjectLine: 'Sign Up for Exclusive SMS Updates!',
    bodyText:
      "<p>Hi there!</p><p><br></p><p>Stay in the loop with our latest offerings and exclusive promotions by joining our new SMS list! Opt-in today to receive instant updates right to your phone.</p><p><br></p><p>Don't miss out—be the first to know!</p>",
    buttonText: 'Sign Up',
    postSendModalTitlePrefix: 'Sign Up for SMS Email',
    trackEventName: 'SMS Signup',
    showCustomProductsOnly: false,
  },
  'new-product': {
    pageTitle: 'Product Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Product to Announce',
    subjectLine: 'A New Product Just Dropped!',
    bodyText:
      'We are excited to announce our latest product! Click on the button below to learn more and place an order.',
    buttonText: 'Order Now',
    postSendModalTitlePrefix: 'Product Announcement',
    trackEventName: 'Product Announcement',
    showCustomProductsOnly: false,
  },
  'custom-order-available': {
    pageTitle: 'New Availability Email',
    pageTitleMobile: 'Availability',
    step1TitleUnselected: 'Which Product Does This Apply To?',
    step1TitleSelectedPrefix: 'New Dates For',
    subjectLine: 'New Dates Available For Custom Orders!',
    bodyText:
      'I just opened up new dates on my calendar. Fill out a request at the link below to request a custom order and secure a date on my calendar!',
    buttonText: 'Submit a Request',
    postSendModalTitlePrefix: 'Availability Announcement',
    trackEventName: 'Custom Order Availability',
    showCustomProductsOnly: true,
  },
  'new-presale': {
    pageTitle: 'Presale Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Presale To Announce',
    subjectLine: 'The Presale You’ve Been Waiting for is Live!',
    bodyText:
      'We are excited to announce our newest presale is live! Click on the button below to see everything we have available and shop the presale while you can.',
    buttonText: 'Shop the Presale',
    postSendModalTitlePrefix: 'Presale Announcement',
    trackEventName: 'Presale Announcement',
    showCustomProductsOnly: false,
  },
  'ticketed-event-announcement': {
    pageTitle: 'Event Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Event To Announce',
    subjectLine: '👋 Will We See You There?',
    bodyText: 'Join us for our upcoming event! Grab your tickets now before they sell out.',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Event Announcement',
    trackEventName: 'Event Announcement',
    showCustomProductsOnly: false,
    showTicketedEvents: true,
    utmCampaign: 'ticketed_event_announcement',
  },
  'fall-announcement': {
    eventName: 'Fall',
    eventTag: 'Fall',
    pageTitle: 'Fall Announcement',
    pageTitleMobile: 'Fall',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🍁 Fall for Something Delicious!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you geared up for a fall season filled with delicious delights? Make these months truly unforgettable with our mouthwatering small-batch treats!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Fall Email',
    trackEventName: 'Fall Announcement',
    showCustomProductsOnly: false,
  },
  'fall-reminder': {
    eventName: 'Fall',
    eventTag: 'Fall',
    pageTitle: 'Fall Reminder',
    pageTitleMobile: 'Fall',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Fall Flavors Flying By!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t miss your chance to grab specialty fall flavors - the season is flying by!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Fall Email',
    trackEventName: 'Fall Reminder',
    showCustomProductsOnly: false,
  },
  'shop-fall': {
    eventName: 'Fall',
    eventTag: 'Fall',
    pageTitle: 'Shop Fall Products',
    pageTitleMobile: 'Fall',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Shop “Unbe-leaf-able” Treats Today!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Check out my fall products in my shop today! 🍂</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Fall Email',
    trackEventName: 'Shop Fall Products',
    showCustomProductsOnly: false,
  },
  'fall-customs': {
    eventName: 'Fall',
    eventTag: 'Fall',
    pageTitle: 'Custom Order for Fall',
    pageTitleMobile: 'Fall',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Custom Goods for Fall Festivities! 📣',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking to add a personal touch to school events, sports games, or other fall gatherings? My shop is now accepting custom orders for small-batch artisanal foods perfect for all autumn occasions!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Fall Email',
    trackEventName: 'Custom Order for Fall',
    showCustomProductsOnly: true,
  },
  'last-call-fall': {
    eventName: 'Fall',
    eventTag: 'Fall',
    pageTitle: 'Last-Call for Fall Products',
    pageTitleMobile: 'Fall',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Don’t “Leaf” These Goodies Behind! 🍂',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">As the leaves fall off the trees, don’t forget to get your orders in for fall products!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Fall Email',
    trackEventName: 'Custom Order for Fall',
    showCustomProductsOnly: false,
  },
  'halloween-announcement': {
    eventName: 'Halloween',
    eventTag: 'Halloween',
    pageTitle: 'Halloween Announcement',
    pageTitleMobile: 'Halloween',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Don’t Get Scared by Boo-ring Treats!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you ready for a hauntingly delicious Halloween holiday? Place your ghoulishly-good orders today! 🎃</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Halloween Email',
    trackEventName: 'Halloween Announcement',
    showCustomProductsOnly: false,
  },
  'halloween-reminder': {
    eventName: 'Halloween',
    eventTag: 'Halloween',
    pageTitle: 'Halloween Reminder',
    pageTitleMobile: 'Halloween',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'No Tricks Here! Order Halloween Treats Today!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">We don’t mean to scare you, but Halloween is coming up fast! Don’t forget to order your treats (and tricks) from my shop today!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Halloween Email',
    trackEventName: 'Halloween Reminder',
    showCustomProductsOnly: false,
  },
  'shop-halloween': {
    eventName: 'Halloween',
    eventTag: 'Halloween',
    pageTitle: 'Shop Halloween Products',
    pageTitleMobile: 'Halloween',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Halloween Products are Live! 🎃',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Shop my frighteningly good selection of Halloween products today. From spooky and scary to sweet, I have something for everyone! 🎂</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Halloween Email',
    trackEventName: 'Shop Halloween Products',
    showCustomProductsOnly: false,
  },
  'halloween-customs': {
    eventName: 'Halloween',
    eventTag: 'Halloween',
    pageTitle: 'Custom Halloween-Themed Order Email',
    pageTitleMobile: 'Halloween',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Spook-tacular Custom Orders!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Candy is dandy, but custom-made artisanal treats create memorable Halloween events! Place your order today before your Halloween festivities! 🦇</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Halloween Email',
    trackEventName: 'Custom Halloween-Themed Order Email',
    showCustomProductsOnly: true,
  },
  'last-call-halloween': {
    eventName: 'Halloween',
    eventTag: 'Halloween',
    pageTitle: 'Last-Call for Halloween Treats',
    pageTitleMobile: 'Halloween',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🎃 Last Call for Spook-tacular Halloween Orders!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t be frightened - Halloween is coming up fast! Get your orders in before they vanish like a ghost in the night!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Halloween Email',
    trackEventName: 'Last-Call for Halloween Treats',
    showCustomProductsOnly: false,
  },
  'thanksgiving-announcement': {
    eventName: 'Thanksgiving',
    eventTag: 'Thanksgiving',
    pageTitle: 'Thanksgiving Announcement',
    pageTitleMobile: 'Thanksgiving',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: '🦃 Gobble Up Something Delicious!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Are you ready for Thanksgiving celebrations filled with a cornucopia of delights? Make your November amazing with our mouthwatering small-batch treats!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Thanksgiving Email',
    trackEventName: 'Thanksgiving Announcement',
    showCustomProductsOnly: false,
  },
  'thanksgiving-reminder': {
    eventName: 'Thanksgiving',
    eventTag: 'Thanksgiving',
    pageTitle: 'Thanksgiving Reminder',
    pageTitleMobile: 'Thanksgiving',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'A Cornucopia of Deliciousness!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t miss your chance to grab Thanksgiving favorites - take the guesswork out of sides, desserts, and more!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Thanksgiving Email',
    trackEventName: 'Thanksgiving Reminder',
    showCustomProductsOnly: false,
  },
  'shop-thanksgiving': {
    eventName: 'Thanksgiving',
    eventTag: 'Thanksgiving',
    pageTitle: 'Shop Thanksgiving Products',
    pageTitleMobile: 'Thanksgiving',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Gobble Up Thanksgiving Treats Today!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Check out my Thanksgiving products in my shop today! 🦃</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Thanksgiving Email',
    trackEventName: 'Shop Thanksgiving Products',
    showCustomProductsOnly: false,
  },
  'thanksgiving-customs': {
    eventName: 'Thanksgiving',
    eventTag: 'Thanksgiving',
    pageTitle: 'Custom Order for Thanksgiving',
    pageTitleMobile: 'Thanksgiving',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Custom Goods for Gatherings! 📣',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking to add a personal touch to Friendsgiving, Thanksgiving, and more? My shop is now accepting custom orders for small-batch artisanal foods perfect for all November gatherings!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Thanksgiving Email',
    trackEventName: 'Custom Order for Thanksgiving',
    showCustomProductsOnly: true,
  },
  'last-call-thanksgiving': {
    eventName: 'Thanksgiving',
    eventTag: 'Thanksgiving',
    pageTitle: 'Last-Call for Thanksgiving Products',
    pageTitleMobile: 'Thanksgiving',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Feast Mode: Activated 🍂',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">The turkey’s been purchased, the fine china’s been dusted off, don’t forget your sweet pairings! Don’t leave your stellar sides without an artisanal accompaniment.</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Thanksgiving Email',
    trackEventName: 'Last-Call for Thanksgiving Products',
    showCustomProductsOnly: false,
  },
  'black-friday-announcement-1': {
    eventName: 'Black Friday',
    pageTitle: 'Black Friday Announcement 1',
    pageTitleMobile: 'Black Friday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Get Ready for Black Friday!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">The holiday season is almost here, and it's the perfect time to find one-of-a-kind gifts!</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Visit my shop for Black Friday and discover a curated selection of unique, handmade goods – perfect for the upcoming holidays.</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Black Friday Email',
    trackEventName: 'Black Friday Announcement 1',
    showCustomProductsOnly: false,
  },
  'black-friday-announcement-2': {
    eventName: 'Black Friday',
    pageTitle: 'Black Friday Announcement 2',
    pageTitleMobile: 'Black Friday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Shop Black Friday Early!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">The holiday season is just around the corner, and there's no better time to start finding those perfect food accompaniments by shopping Black Friday!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Black Friday Email',
    trackEventName: 'Black Friday Announcement 2',
    showCustomProductsOnly: false,
  },
  'black-friday-announcement-3': {
    eventName: 'Black Friday',
    pageTitle: 'Black Friday Announcement 3',
    pageTitleMobile: 'Black Friday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Get Ahead on Holiday Treat Shopping!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">With the holidays fast approaching, now’s the perfect moment to preorder delicious treats on Black Friday!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Black Friday Email',
    trackEventName: 'Black Friday Announcement 3',
    showCustomProductsOnly: false,
  },
  'small-business-saturday-1': {
    eventName: 'Small Business Saturday',
    pageTitle: 'Small Business Saturday 1',
    pageTitleMobile: 'Small Business Saturday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Shop Here for Small Business Saturday!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">This Small Business Saturday, support local and shop handmade treats from my small business! Discover unique, carefully crafted goodies perfect for holiday gifting or treating yourself.</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Small Business Saturday Email',
    trackEventName: 'Small Business Saturday Announcement 1',
    showCustomProductsOnly: false,
  },
  'small-business-saturday-2': {
    eventName: 'Small Business Saturday',
    pageTitle: 'Small Business Saturday 2',
    pageTitleMobile: 'Small Business Saturday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Small Business Saturday Inspo!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking for small businesses to support on Small Business Saturday? Look no further than my online artisan shop!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Small Business Saturday Email',
    trackEventName: 'Small Business Saturday Announcement 2',
    showCustomProductsOnly: false,
  },
  'small-business-saturday-3': {
    eventName: 'Small Business Saturday',
    pageTitle: 'Small Business Saturday 3',
    pageTitleMobile: 'Small Business Saturday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Remember - Small Business Saturday!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">This Small Business Saturday, support local and shop handmade! Explore my collection of unique, carefully crafted goodies —perfect for holiday gifting or treating yourself to something special.</span></p>
      <p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Thank you for supporting small businesses like mine!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Small Business Saturday Email',
    trackEventName: 'Small Business Saturday Announcement 3',
    showCustomProductsOnly: false,
  },
  'cyber-monday-announcement-1': {
    eventName: 'Cyber Monday',
    pageTitle: 'Cyber Monday Announcement 1',
    pageTitleMobile: 'Cyber Monday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Go Unique this Cyber Monday!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">This Cyber Monday, forget the big-box stores and treat yourself (or a loved one) to something truly special! Explore my shop for goodies that make perfect one-of-a-kind gifts.</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Cyber Monday Email',
    trackEventName: 'Black Friday Announcement 1',
    showCustomProductsOnly: false,
  },
  'cyber-monday-announcement-2': {
    eventName: 'Cyber Monday',
    pageTitle: 'Cyber Monday Announcement 2',
    pageTitleMobile: 'Cyber Monday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Shop Outside the Box on Cyber Monday!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Why go for the ordinary this Cyber Monday when you can shop unique, handmade foods instead?</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Cyber Monday Email',
    trackEventName: 'Black Friday Announcement 2',
    showCustomProductsOnly: false,
  },
  'cyber-monday-announcement-3': {
    eventName: 'Cyber Monday',
    pageTitle: 'Cyber Monday Announcement 3',
    pageTitleMobile: 'Cyber Monday',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Break the Mold on Cyber Monday!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">This Cyber Monday, skip the mainstream and discover something truly distinctive for holidays and beyond!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Cyber Monday Email',
    trackEventName: 'Black Friday Announcement 3',
    showCustomProductsOnly: false,
  },

  'christmas-announcement': {
    eventName: 'Christmas',
    eventTag: 'Christmas',
    pageTitle: 'Christmas Announcement',
    pageTitleMobile: 'Christmas',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Chill Out with Delicious Goodies! ❄️',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Plan ahead for the winter holidays with small-batch artisanal goodies from my shop!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Christmas Email',
    trackEventName: 'Christmas Announcement',
    showCustomProductsOnly: false,
  },
  'christmas-reminder': {
    eventName: 'Christmas',
    eventTag: 'Christmas',
    pageTitle: 'Christmas Reminder',
    pageTitleMobile: 'Christmas',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Make the Nice List with Delicious Treats!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Make your holiday list and check it twice 🎄 be sure to order your festive winter treats before time runs out!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Christmas Email',
    trackEventName: 'Christmas Reminder',
    showCustomProductsOnly: false,
  },
  'shop-christmas': {
    eventName: 'Christmas',
    eventTag: 'Christmas',
    pageTitle: 'Shop Christmas Products',
    pageTitleMobile: 'Christmas',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Holiday Treats: Live!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Explore my wonderfully festive selection of winter holiday treats today! From cozy and cheerful to deliciously sweet, there’s something for everyone to enjoy! 🎄</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Christmas Email',
    trackEventName: 'Shop Christmas Products',
    showCustomProductsOnly: false,
  },
  'christmas-customs': {
    eventName: 'Christmas',
    eventTag: 'Christmas',
    pageTitle: 'Custom Christmas Order Email',
    pageTitleMobile: 'Christmas',
    step1TitleUnselected: 'Featured Product',
    subjectLine: 'Dazzling Custom Orders! 🌨️',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Custom-made artisanal treats make winter holiday celebrations truly unforgettable! Place your order today to ensure your festive gatherings are extra special! ❄️</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Christmas Email',
    trackEventName: 'Custom Christmas Order Email',
    showCustomProductsOnly: true,
  },
  'last-call-christmas': {
    eventName: 'Christmas',
    eventTag: 'Christmas',
    pageTitle: 'Last-Call for Christmas Treats',
    pageTitleMobile: 'Christmas',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Don’t Be Iced Out: Order Today!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Christmas, Hanukkah, and Kwanzaa are just around the corner! Get your orders in before December melts away like snowflakes in the sun! ❄️✨</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Christmas Email',
    trackEventName: 'Last-Call for Christmas Treats',
    showCustomProductsOnly: false,
  },
  'hanukkah-announcement': {
    eventName: 'Hanukkah',
    eventTag: 'Hanukkah',
    pageTitle: 'Hanukkah Announcement',
    pageTitleMobile: 'Hanukkah',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Celebrate the Festival of Lights! 🕎',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hanukkah is just around the corner, and it’s the perfect time to light up the season with meaningful goodies and festive cheer!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Hanukkah Email',
    trackEventName: 'Hanukkah Announcement',
    showCustomProductsOnly: false,
  },
  'hanukkah-reminder': {
    eventName: 'Hanukkah',
    eventTag: 'Hanukkah',
    pageTitle: 'Hanukkah Reminder',
    pageTitleMobile: 'Hanukkah',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'A Festival of Gifts for Hanukkah! 🕎',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Celebrate the season of lights with delicious treats that shine as bright as the menorah!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Hanukkah Email',
    trackEventName: 'Shop Hanukkah Products',
    showCustomProductsOnly: false,
  },
  'kwanzaa-announcement': {
    eventName: 'Kwanzaa',
    eventTag: 'Kwanzaa',
    pageTitle: 'Kwanzaa Announcement',
    pageTitleMobile: 'Kwanzaa',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Celebrate Unity and Heritage this Kwanzaa! ✊🏾',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Kwanzaa is a time to honor culture, family, and community—why not make this celebration even more special with meaningful food?</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Kwanzaa Email',
    trackEventName: 'Kwanzaa Announcement',
    showCustomProductsOnly: false,
  },
  'kwanzaa-reminder': {
    eventName: 'Kwanzaa',
    eventTag: 'Kwanzaa',
    pageTitle: 'Kwanzaa Reminder',
    pageTitleMobile: 'Kwanzaa',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'A Bounty of Gifts for Kwanzaa and Beyond!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Want to make your Kwanzaa celebrations even more special? Be sure to pick up the perfect artisanal goods to add a personal touch to your holiday gatherings!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Kwanzaa Email',
    trackEventName: 'Shop Kwanzaa Products',
    showCustomProductsOnly: false,
  },
  'nye-announcement': {
    eventName: "New Year's Eve",
    eventTag: 'NewYears',
    pageTitle: "New Year's Eve Announcement",
    pageTitleMobile: "New Year's Eve",
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Set off Culinary Fireworks! 🎆',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking for something delicious and memorable to ring in 2025 with? Look no further! Shop handmade artisanal goodies today.</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "New Year's Eve Email",
    trackEventName: "New Year's Eve Announcement",
    showCustomProductsOnly: false,
  },
  'shop-nye': {
    eventName: "New Year's Eve",
    eventTag: 'NewYears',
    pageTitle: "Shop New Year's Eve Products",
    pageTitleMobile: "New Year's Eve",
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Make Memories this New Year’s!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Whether you’re hosting a small gathering, or an elaborate celebration - check out artisanal goodies in my shop today!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "New Year's Eve Email",
    trackEventName: "Shop New Year's Eve Products",
    showCustomProductsOnly: false,
  },
  'last-call-nye': {
    eventName: "New Year's Eve",
    eventTag: 'NewYears',
    pageTitle: "Last-Call for New Year's Eve Treats",
    pageTitleMobile: "New Year's Eve",
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Don’t Let Time Run Out!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Be sure to grab your New Year’s Eve party treats before the clock strikes midnight and 2025 is here!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "New Year's Eve Email",
    trackEventName: "Last-Call for New Year's Eve Treats",
    showCustomProductsOnly: false,
  },
  'valentines-announcement': {
    eventName: "Valentine's Day",
    eventTag: 'ValentinesDay',
    pageTitle: "Valentine’s Day Announcement",
    pageTitleMobile: "Valentine’s Day",
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Take a Bite of Sweetness! 💘',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">This Valentine's Day, let special handmade treats show your loved ones how much you care. Shop my goods today!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "Valentine's Day Email",
    trackEventName: "Valentine’s Day Announcement",
    showCustomProductsOnly: false,
  },
  'shop-valentines': {
    eventName: "Valentine's Day",
    eventTag: 'ValentinesDay',
    pageTitle: "Valentine’s Day New Product",
    pageTitleMobile: "Valentine’s Day",
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'A Special Treat, Just for You! 💗',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Love is in the air, and we want to help you celebrate with new, delicious products. Order today!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "Valentine's Day Email",
    trackEventName: "Valentine’s Day New Product",
    showCustomProductsOnly: false,
  },
  'last-call-valentines': {
    eventName: "Valentine's Day",
    eventTag: 'ValentinesDay',
    pageTitle: "Valentine’s Day Last-Call",
    pageTitleMobile: "Valentine’s Day",
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Don’t Miss Out: Valentine’s Day!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Think outside the chocolate box with handmade artisanal treats - order soon!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "Valentine's Day Email",
    trackEventName: "Valentine’s Day Last-Call",
    showCustomProductsOnly: false,
  },
  'big-game-announcement': {
    pageTitle: 'Big Game Announcement',
    pageTitleMobile: 'Big Game',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Score Big with Game-Time Treats!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Forget the chips and dip, make your Big Game party memorable with new products in my shop!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Big Game Email',
    trackEventName: 'Big Game Announcement',
    showCustomProductsOnly: false,
    utmCampaign: 'thebiggame',
  },
  'big-game-new-products': {
    pageTitle: 'Big Game New Products',
    pageTitleMobile: 'Big Game',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Huddle Up with Game Goodies! 🏈',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">No matter which team you’re rooting for, my new handmade treats are a way to win over guests!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Big Game Email',
    trackEventName: 'Big Game New Products',
    showCustomProductsOnly: false,
    utmCampaign: 'thebiggame',
  },
  'big-game-last-call': {
    pageTitle: 'Big Game Last-Call',
    pageTitleMobile: 'Big Game',
    step1TitleUnselected: 'Featured Product or Presale',
    subjectLine: 'Kickoff is Soon: Score Tasty Treats!',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Score a touchdown with delicious goodies in time for your Big Game party! 🏈</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Big Game Email',
    trackEventName: 'Big Game Last-Call',
    showCustomProductsOnly: false,
    utmCampaign: 'thebiggame',
  },
};
