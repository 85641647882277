import { Transaction } from "@castiron/domain";
import _ from 'lodash';
import moment from 'moment-timezone';

interface CalendarTransactionEvent {
  title: string;
  startDate: string;
  endDate: string;
  transactions: Transaction[];
};

interface CuratedTransactions {
  calendarTransactionEvents: CalendarTransactionEvent[];
  missingQuotes: Transaction[];
  missingOrders: Transaction[];
};

const earliestRequiredQuoteFulfillmentDate = moment('2022-08-17').unix();
const earliestRequiredOrderFulfillmentDate = moment('2023-01-11').unix();


const CALENDAR_EVENT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
const DISPLAY_QUOTE_STATUSES = ['draft', 'new', 'pending', 'partially paid'];
const DISPLAY_ORDER_STATUSES = ['open', 'completed', 'fulfilled'];

const whichDate = (tx: any): number => {
  return _.get(tx, 'order.fulfillmentOption.schedule.dates[0].startTime') ||
    tx.order.fulfillmentOption?.date || tx.order.requestedFulfillment?.date;
};

const curateCalendarDay = (txes: Transaction[], maxDailyGroup: number, startHour: number): CalendarTransactionEvent[] => {
  const [quotes, orders] = _.partition(txes, tx => tx.order.type === 'custom');
  const [viewableQuotes, otherQuotes] = _.partition(quotes, tx => DISPLAY_QUOTE_STATUSES.includes(tx.frontendState('quote')));
  const customOrders = otherQuotes.filter(tx => tx.frontendState('quote') === 'paid');
  const viewableOrders = orders.filter(tx => DISPLAY_ORDER_STATUSES.includes(tx.frontendState('order')));

  const sortByOrderNumber = (txes: Transaction[]): Transaction[] => _.sortBy(txes, tx => tx.order.orderNumber);
  const calendarEvents = [
    ...sortByOrderNumber(viewableQuotes),
    ...sortByOrderNumber(customOrders),
    ...sortByOrderNumber(viewableOrders)
  ];

  const curatedCalendarEvents = calendarEvents.reduce((accum, tx, index) => {
    if (accum.length >= maxDailyGroup) {
      const lastElement = accum[accum.length - 1];
      return [
        ...accum.slice(0, accum.length - 1),
        { ...lastElement, title: 'rest', transactions: [...lastElement.transactions, tx] },
      ];
    } else {
      /* want them every half hour for day and week views */
      const fulfillmentTime = moment
        .unix(whichDate(tx))
        .hour(index / 2 + startHour)
        .minute((index % 2) * 30)
        .second(0)
        .millisecond(0);
      /* to avoid weird time zone issues converting between moment and vanilla dates, just use strings with local times */
      const fulfillmentDate = fulfillmentTime.format(CALENDAR_EVENT_DATE_FORMAT);
      /* stupid mutable moment */
      const fulfillmentEndDate = fulfillmentTime.clone().add(30, 'minutes').format(CALENDAR_EVENT_DATE_FORMAT);
      return [
        ...accum,
        { title: tx.order.orderNumber, startDate: fulfillmentDate, endDate: fulfillmentEndDate, transactions: [tx] },
      ];
    }
  }, []);

  return curatedCalendarEvents;
};

export const curateTransactions = (txes: Transaction[], maxDailyGroup: number, startHour: number): CuratedTransactions => {
  const [withDates, withoutDates] = _.partition(txes, tx => whichDate(tx));
  const byDate = _.groupBy(withDates, tx => moment.unix(whichDate(tx)).format('YYYYMMDD'));
  const calendarTransactionEvents = _.flatMap(byDate, txes => curateCalendarDay(txes, maxDailyGroup, startHour));

  const [missingQuotes, ordersWithoutDates] = _.partition(
    withoutDates.filter(tx => earliestRequiredQuoteFulfillmentDate <= tx.createdAt),
    tx => tx.order.type === 'custom' && DISPLAY_QUOTE_STATUSES.includes(tx.frontendState('quote')),
  );
  const missingOrders = ordersWithoutDates.filter(order => earliestRequiredOrderFulfillmentDate <= order.createdAt && DISPLAY_ORDER_STATUSES.includes(order.frontendState('order')));

  return {
    calendarTransactionEvents,
    missingQuotes,
    missingOrders,
  };
};