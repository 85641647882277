import _ from 'lodash';
import {
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
  useTheme,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
} from '@material-ui/core';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { calculateTotals, calculateTotalsForDepositOrder, Order, TransactionTotals } from '@castiron/domain';
import { useFormikContext } from 'formik';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Dinero from 'dinero.js';
import Tooltip from '../../Tooltip';
import { CollapsableCard, CustomSwitch, Input, MoneyInput } from '@castiron/components';
import { defaultTimeZone } from '@castiron/utils';

type Props = {
  order: Order;
  isPaid: boolean;
  title: string;
  defaultExpanded?: boolean;
  setHasSubTransactions: (hasSubTransactions: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: '24px 0',
    width: '100%',
  },
  formLabel: {
    color: '#000',
    fontWeight: 700,
    fontSize: '14px',
    marginBottom: 0,
  },
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  numberInput: {
    width: '100%',
    height: 48,
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  switch: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    '& .MuiSwitch-root': {
      alignSelf: 'flex-start',
    },
    '& .MuiFormControlLabel-label': {
      paddingRight: 0,
    },
  },
  tooltip: {
    marginBottom: '-1px',
  },
}));

const QuotePayment: React.FC<Props> = (props: Props) => {
  const { order, isPaid, title, defaultExpanded = false, setHasSubTransactions } = props;
  const { values, setFieldValue, errors, touched }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const [totals, setTotals] = useState<TransactionTotals>();
  const [optionChecked, setOptionChecked] = useState<string>('24hours');
  const [depositOptionChecked, setDepositOptionChecked] = useState<string>('24hours');
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;
  const nowUnix = moment().tz(timeZone).unix();

  useEffect(() => {
    if (order?.paymentDueDate) {
      setOptionChecked('date');
      setFieldValue('order.paymentSentDuePeriod', undefined);
    } else if (order?.paymentSentDuePeriod) {
      setOptionChecked(values.order.paymentSentDuePeriod);
    }
    if (values?.subTransactions?.paymentDueDate) {
      setDepositOptionChecked('date');
      setFieldValue('subTransactions.paymentSentDuePeriod', undefined);
    } else if (values?.subTransactions?.paymentSentDuePeriod) {
      setDepositOptionChecked(values?.subTransactions?.paymentSentDuePeriod);
    }
  }, [order]);

  useEffect(() => {
    if (shop) {
      const totals = calculateTotals({
        order: values.order,
        paymentSettings: shop?.paymentSettings,
      });
      setTotals(totals);
    }
  }, [shop, values.order, values?.subTransactions?.amount, values?.subTransactions?.percentageAmount]);

  const blurOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      /* types not playing nice */
      (e.target as any).blur();
    }
  };

  const onPaymentChange = event => {
    const selection = event.target.value;
    switch (selection) {
      case 'none':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', 'none');
        setFieldValue('order.paymentDueDate', null);
        break;
      case '0day':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', '0day');
        setFieldValue('order.paymentDueDate', null);
        break;
      case '24hours':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', '24hours');
        setFieldValue('order.paymentDueDate', null);
        break;
      case '7days':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', '7days');
        setFieldValue('order.paymentDueDate', null);
        break;
      case '14days':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', '14days');
        setFieldValue('order.paymentDueDate', null);
        break;
      case 'date':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', undefined);
    }
  };

  const onDepositPaymentChange = event => {
    const selection = event.target.value;
    switch (selection) {
      case 'none':
        setDepositOptionChecked(selection);
        setFieldValue('subTransactions.paymentSentDuePeriod', 'none');
        setFieldValue('subTransactions.paymentDueDate', null);
        break;
      case '24hours':
        setDepositOptionChecked(selection);
        setFieldValue('subTransactions.paymentSentDuePeriod', '24hours');
        setFieldValue('subTransactions.paymentDueDate', null);
        break;
      case '7days':
        setDepositOptionChecked(selection);
        setFieldValue('subTransactions.paymentSentDuePeriod', '7days');
        setFieldValue('subTransactions.paymentDueDate', null);
        break;
      case 'date':
        setDepositOptionChecked(selection);
        setFieldValue('subTransactions.paymentSentDuePeriod', undefined);
    }
  };

  const onDepositDateChange = value => {
    setDepositOptionChecked('date');
    setFieldValue(
      'subTransactions.paymentDueDate',
      value ? value.set({ hour: 12, minute: 0, second: 0, milliseconds: 0 }).unix() : null,
    );
    setFieldValue('subTransactions.paymentSentDuePeriod', undefined);
  };

  const onDateChange = value => {
    setOptionChecked('date');
    setFieldValue(
      'order.paymentDueDate',
      value ? value.set({ hour: 12, minute: 0, second: 0, milliseconds: 0 }).unix() : null,
    );
    setFieldValue('order.paymentSentDuePeriod', undefined);
  };

  const handleHasSubTransactionsToggle = async checked => {
    if (checked) {
      await setFieldValue('subTransactions.hasSubTransactions', true);
      setHasSubTransactions(true);
    } else {
      await setFieldValue('subTransactions.hasSubTransactions', false);
      await setFieldValue('subTransactions.amount', 0);
      await setFieldValue('subTransactions.amountType', 'dollars');
      await setFieldValue('subTransactions.paymentDueDate', null);
      await setFieldValue('subTransactions.percentageAmount', 0);
      await setFieldValue('subTransactions.paymentSentDuePeriod', '24hours');
      setHasSubTransactions(false);
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  
  let balanceAmount;
  if (
    values?.subTransactions?.hasSubTransactions &&
    (values?.subTransactions?.amount !== 0 || values?.subTransactions?.percentageAmount !== 0)
  ) {
    const subTransactionTotal =
      values?.subTransactions?.amountType == 'dollars'
        ? values?.subTransactions?.amount || 0
        : Math.round((values?.subTransactions?.percentageAmount / 100) * totals?.subtotal) || 0;
    const balanceTotals = calculateTotals({
      order: values.order,
      paymentSettings: shop.paymentSettings,
      subTransactionTotal,
      isSubTransactionPaid: true,
    });
    balanceAmount = Dinero({
      amount: balanceTotals?.total || 0,
    }).toFormat('$0.00');
  }

  const depositDueDateOptions = [
    { value: '24hours', label: `Within 24 hours of sending ${title.toLowerCase()}` },
    { value: '7days', label: `Within 7 days of sending ${title.toLowerCase()}` },
    { value: 'date', label: 'Custom' },
  ];
  const balanceDueDateOptions = [
    {
      value: '7days',
      label: `1 week prior to ${values?.order?.fulfillmentOption?.type}${
        values?.order?.fulfillmentOption?.date
          ? `- ${moment
              .unix(values?.order?.fulfillmentOption?.date)
              .tz(timeZone)
              .subtract(1, 'weeks')
              .format('M/D/YYYY')}`
          : ``
      }`,
      moment: moment
        .unix(values?.order?.fulfillmentOption?.date)
        .tz(timeZone)
        .subtract(1, 'weeks'),
    },
    {
      value: '14days',
      label: `2 weeks prior to ${values?.order?.fulfillmentOption?.type}${
        values?.order?.fulfillmentOption?.date
          ? `- ${moment
              .unix(values?.order?.fulfillmentOption?.date)
              .tz(timeZone)
              .subtract(2, 'weeks')
              .format('M/D/YYYY')}`
          : ``
      }`,
      moment: moment
        .unix(values?.order?.fulfillmentOption?.date)
        .tz(timeZone)
        .subtract(2, 'weeks'),
    },
    {
      value: '0day',
      label: `On date of ${values?.order?.fulfillmentOption?.type}${
        values?.order?.fulfillmentOption?.date
          ? `- ${moment
              .unix(values?.order?.fulfillmentOption?.date)
              .tz(timeZone)
              .format('M/D/YYYY')}`
          : ``
      }`,
      moment: moment.unix(values?.order?.fulfillmentOption?.date).tz(timeZone),
    },
  ];
  const validDepositDueDateOptions = depositDueDateOptions;
  const validBalanceDueDateOptions = balanceDueDateOptions.filter(option => option.moment.unix() >= nowUnix);

  return (
    <CollapsableCard title="Payment" handleExpand={toggleExpand} expanded={expanded} noScroll>
      <Typography variant="h4">Deposit</Typography>
      <FormGroup>
        <FormControlLabel
          className={classes.switch}
          control={
            <CustomSwitch
              className={classes.switch}
              label={true}
              checked={values?.subTransactions?.hasSubTransactions}
              disabled={isPaid}
              onChange={async e => await handleHasSubTransactionsToggle(e.target.checked)}
            />
          }
          label={<Typography variant="body2">Require deposit</Typography>}
          name="subTransactions.hasSubTransactions"
        />
        {values?.subTransactions?.hasSubTransactions && (
          <FormControl className={classes.formControl} variant="outlined">
            <Typography>Amount</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                {values?.subTransactions?.amountType == 'dollars' ? (
                  <MoneyInput
                    name="subTransactions.amount"
                    placeholder="0.00"
                    disabled={isPaid}
                    onChanged={async newValue => await setFieldValue('subTransactions.amount', newValue)}
                    onKeyPress={blurOnEnter}
                    variant="small"
                    error={touched?.subTransactions?.amount && errors?.subTransactions?.amount}
                  />
                ) : (
                  <Input
                    type="number"
                    hideArrows={true}
                    onChange={event => setFieldValue('subTransactions.percentageAmount', event.target.valueAsNumber)}
                    value={values.subTransactions.percentage}
                    name="subTransactions.percentageAmount"
                    variant="outlined"
                    disabled={isPaid}
                    margin="none"
                    error={touched?.subTransactions?.percentageAmount && errors?.subTransactions?.percentageAmount}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  displayEmpty={false}
                  value={values?.subTransactions?.amountType}
                  style={{ width: '100%', height: 48 }}
                  onChange={event => setFieldValue('subTransactions.amountType', event.target.value)}
                  disabled={isPaid}
                >
                  {['dollars', 'percentage'].map(type => (
                    <MenuItem className={classes.selectOptionContainer} key={type} value={type}>
                      <Typography className={classes.selectOption} variant="body1">
                        {_.capitalize(type)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}></Grid>
              <Typography variant="body2" style={{ padding: '5px' }}>
                Customers will be charged fees on this deposit amount based on your current{' '}
                <a href="/store/payments" style={{ color: 'inherit', textDecoration: 'underline' }}>
                  fee settings
                </a>
                .
              </Typography>
            </Grid>
            <Grid container item xs={12} alignItems="flex-end" style={{ paddingTop: 16 }}>
              <Typography>Due Date</Typography>
              <Tooltip
                tooltipClass={classes.tooltip}
                title="Orders do not automatically become inactive after this date, but we will send automated reminders to you and your customer to secure their order before the due date has passed."
              />
            </Grid>
            <RadioGroup
              aria-label="payment due date"
              name="subTransactions.paymentDueDate"
              value={depositOptionChecked}
              onChange={onDepositPaymentChange}
            >
              {validDepositDueDateOptions.map(option => (
                <FormControlLabel
                  key={`deposit-${option.value}`}
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                  disabled={isPaid}
                />
              ))}
              {values?.subTransactions.paymentSentDuePeriod == undefined && (
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disablePast={true}
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      id="date-picker-inline-deposits"
                      inputVariant="outlined"
                      value={
                        values?.subTransactions?.paymentDueDate
                          ? moment.unix(values?.subTransactions?.paymentDueDate)
                          : null
                      }
                      onChange={onDepositDateChange} // ternary prevents error when deleting last character
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={isPaid}
                      error={touched?.subTransactions?.paymentDueDate && errors?.subTransactions?.paymentDueDate}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.subTransactions?.paymentDueDate && touched?.subTransactions?.paymentDueDate && (
                    <Typography variant="caption" style={{ color: theme.branding.v2.red[400] }}>
                      Must select a date if custom
                    </Typography>
                  )}
                </>
              )}
            </RadioGroup>
          </FormControl>
        )}
      </FormGroup>
      <Typography variant="h4">Balance</Typography>
      <Typography style={{ paddingTop: 8 }}>Amount</Typography>
      <Grid style={{ padding: '8px 0px' }}>
        <MoneyInput disabled={true} disabledStyle={true} placeholder={balanceAmount} />
      </Grid>
      <FormControl className={classes.formControl} variant="outlined">
        <Grid container item xs={12} alignItems="flex-end">
          <Typography>Due Date</Typography>
          <Tooltip
            tooltipClass={classes.tooltip}
            title="Orders do not automatically become inactive after this date, but we will send automated reminders to you and your customer to secure their order before the due date has passed."
          />
        </Grid>
        <RadioGroup
          aria-label="payment due date"
          name="order.paymentDueDate"
          value={optionChecked}
          onChange={onPaymentChange}
        >
          {!values?.subTransactions?.hasSubTransactions ? (
            <>
              <FormControlLabel value="none" control={<Radio color="primary" />} label="None" disabled={isPaid} />
              <FormControlLabel
                value="24hours"
                control={<Radio color="primary" />}
                label={`Within 24 hours of sending ${title.toLowerCase()}`}
                disabled={isPaid}
              />
              <FormControlLabel
                value="7days"
                control={<Radio color="primary" />}
                label={`Within 7 days of sending ${title.toLowerCase()}`}
                disabled={isPaid}
              />
            </>
          ) : (
            <>
              {validBalanceDueDateOptions.map(option => (
                <FormControlLabel
                  key={`balance-${option.value}`}
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                  disabled={isPaid}
                />
              ))}
            </>
          )}
          <FormControlLabel value="date" control={<Radio color="primary" />} label="Custom" disabled={isPaid} />
          {values?.order?.paymentSentDuePeriod == undefined && (
            <>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disablePast={true}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  inputVariant="outlined"
                  value={values.order.paymentDueDate ? moment.unix(values.order.paymentDueDate) : null}
                  onChange={onDateChange} // ternary prevents error when deleting last character
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disabled={isPaid}
                  error={touched?.order?.paymentDueDate && errors?.order?.paymentDueDate}
                />
              </MuiPickersUtilsProvider>
              {errors?.order?.paymentDueDate && touched?.order?.paymentDueDate && (
                <Typography variant="caption" style={{ color: theme.branding.v2.red[400] }}>
                  Must select a date if custom
                </Typography>
              )}
            </>
          )}
        </RadioGroup>
      </FormControl>
    </CollapsableCard>
  );
};

export default QuotePayment;
